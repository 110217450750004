import {
  TURN_ON_OFF_CHARGE,
  TURN_ON_OFF_CHARGE_LIST_STATUS,
  TURN_ON_OFF_CHARGE_REMOVE,
  TURN_ON_OFF_CHARGE_UPDATE
} from "../Actions/Constants";
import uuidv4 from "uuid/v4";

const initialState = {
  id: uuidv4(),
  data: {
    readingChargeAsConsumer: "ACCEPTED",
    readingChargeAsPsychic: "ACCEPTED"
  },
  error: "",
  isTurnOnCharging: 0
};

export function getTurnOnOffChargeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TURN_ON_OFF_CHARGE:
      return {
        ...state,
        data: action.payload,
        error: "",
        isTurnOnCharging: 0,
        id: uuidv4()
      };
    case TURN_ON_OFF_CHARGE_REMOVE:
      return {
        ...state,
        data: {},
        error: "",
        isTurnOnCharging: 0,
        id: uuidv4()
      };
    case TURN_ON_OFF_CHARGE_UPDATE:
      return {
        ...state,
        data: action.payload,
        error: "",
        isTurnOnCharging: 0,
        id: uuidv4()
      };
    case TURN_ON_OFF_CHARGE_LIST_STATUS:
      return {
        ...state,
        isTurnOnCharging: action.payload,
        error: "",
        id: uuidv4()
      };
    default:
      return state;
  }
}
