import {
  SAVE_FCM_TOKEN,
  SHOW_HIDE_USER_DELETED_POPUP,
  SHOW_PSYCHIC_USER_SUSPENDED_POPUP,
  UPDATE_ARCHIVE_CHAT
} from "../Actions/Constants";

const initialState = {
  id: null,
  isUserDeleted: false,
  isPsychicSuspended: false,
  fcmToken: null,
  data: {
    isArchived: false
  }
};

export function CommonDataReducer(state = initialState, action = {}) {
  const { id, data } = action.payload || {};
  if (action.type === UPDATE_ARCHIVE_CHAT) {
    return {
      ...state,
      ...{
        id: id,
        data: {
          ...data,
          isArchived: data.isArchived
        }
      }
    };
  } else if (action.type === SHOW_HIDE_USER_DELETED_POPUP) {
    return {
      ...state,
      isUserDeleted: action.payload
    };
  } else if (action.type === SHOW_PSYCHIC_USER_SUSPENDED_POPUP) {
    return {
      ...state,
      isPsychicSuspended: action.payload
    };
  } else if (action.type === SAVE_FCM_TOKEN) {
    return {
      ...state,
      fcmToken: action.payload
    };
  }

  return state;
}
