import ApiService from "../Services/Api/ApiService";
import { REACT_APP_API_URL } from "../Components/processENV";
import { encryptAndStoreInLocalStorage } from "../Components/WebChat/WebChatEncryptDecrypt";
import { getFromLocalStorageAndDecrypt } from "../Components/WebChat/WebChatEncryptDecrypt";
import SDK from "../Components/SDK";
import { GET_SETTINGS_SUCCESS } from "./Constants";

const api = new ApiService();

export function Settings() {
  let token = getFromLocalStorageAndDecrypt("token");
  let decryptResponse = getFromLocalStorageAndDecrypt("auth_user");

  api.get({
    url: `${REACT_APP_API_URL}/users/config`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    onSuccess: async (res) => {
      try {
        console.log(res, "settingsRes");
        const { data } = res;
        localStorage.setItem("settings", data);
        SDK.getSettings(data, decryptResponse.username + decryptResponse.username + decryptResponse.username);
        let s = getFromLocalStorageAndDecrypt("settings");
        console.log(s, "pon");
        // encryptAndStoreInLocalStorage('settings', res);
        // console.log("settings",settings);
        console.log("Settings stored successfully.");
      } catch (error) {
        console.error("Error processing settings:", error);
      }
    },
    onError: (err) => {
      console.log(err, "settings err");
    }
  });
}

export function AdminSettings() {
  let token = getFromLocalStorageAndDecrypt("token");

  api.get({
    url: `${REACT_APP_API_URL}/admin/settings`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    onSuccess: async (res) => {
      try {
        const { data } = res;
        // encryptAndStoreInLocalStorage('settings', res);
        // return {
        //   type: GET_SETTINGS_SUCCESS,
        //   payload: {
        //       id: uuidv4(),
        //       data
        //   }
        // }
      } catch (error) {
        console.error("Error processing settings:", error);
      }
    },
    onError: (err) => {
      console.log(err, "settings err");
    }
  });
}
