import { STORE_NOTIFICATION_LIST_DATA } from "../Actions/Constants";

const initialState = {
  NotificationDataStoredStatus: false,
  NotificatonData: []
};

export function getNotificationListDataReducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_NOTIFICATION_LIST_DATA:
      return {
        ...state,
        NotificationDataStoredStatus: true,
        NotificatonData: action.payload
      };
    default:
      return state;
  }
}
