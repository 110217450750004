import {
  FETCH_PSYCHICS_REQUEST,
  FETCH_PSYCHICS_SUCCESS,
  FETCH_PSYCHICS_FAILURE,
  FETCH_PSYCHICS_CLEAR,
  FETCH_PSYCHICS_REELS_CLEAR,
  FETCH_PSYCHICS_REELS_FAILURE,
  FETCH_PSYCHICS_REELS_REQUEST,
  FETCH_PSYCHICS_REELS_SUCCESS,
  FETCH_PSYCHICS_REELS_REFRESH_UPDATE,
  FETCH_PSYCHICS_REFRESH_UPDATE,
  STORE_FILLTER_SETTING,
  STORE_FILLTER_SETTING_CLEAR,
  FETCH_PSYCHICS_REELS_DELETE
} from "../Actions/PsychicAction";

const initialState = {
  loading: false,
  psychics: {
    content: [],
    count: 0,
    totalPages: 0
  },

  psychicsReels: {
    content: [],
    count: 0,
    totalPages: 0
  },
  filterSetting: null,
  error: ""
};

export const psychicReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PSYCHICS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PSYCHICS_CLEAR:
      return {
        ...state,
        loading: false,
        psychics: {
          content: [],
          count: 0,
          totalPages: 0
        },
        error: ""
      };
    case FETCH_PSYCHICS_SUCCESS:
      let updatedContent = [];
      if (action.payload.existValueAddOrNot) {
        updatedContent = [...state.psychics.content, ...action.payload.content];
      } else {
        updatedContent = [...action.payload.content];
      }
      return {
        ...state,
        psychics: {
          ...action.payload,
          content: updatedContent
        },
        error: "",
        loading: false
      };
    case FETCH_PSYCHICS_FAILURE:
      return {
        ...state,
        loading: false,
        psychics: {
          ...state.psychics,
          content: [] // Clear content on failure
        },
        error: action.payload
      };

    case FETCH_PSYCHICS_REFRESH_UPDATE:
      const obj = action.payload;
      const updatedListPsychic = state.psychics.content.map((i) => {
        if (i.id == obj.username) {
          return obj;
        } else {
          return i;
        }
      });
      return {
        ...state,
        psychics: {
          ...state.psychics,
          content: updatedListPsychic
        },
        error: ""
      };

    case FETCH_PSYCHICS_REELS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case FETCH_PSYCHICS_REELS_CLEAR:
      return {
        ...state,
        loading: false,
        psychicsReels: {
          content: [],
          count: 0,
          totalPages: 0
        },
        error: ""
      };
    case FETCH_PSYCHICS_REELS_SUCCESS:
      let updatedContentReels = [];
      if (action.payload.existValueAddOrNot) {
        updatedContentReels = [...state.psychicsReels.content, ...action.payload.data];
      } else {
        updatedContentReels = [...action.payload.data];
      }
      return {
        ...state,
        psychicsReels: {
          ...action.payload,
          content: updatedContentReels
        },
        error: "",
        loading: false
      };
    case FETCH_PSYCHICS_REELS_FAILURE:
      return {
        ...state,
        loading: false,
        psychicsReels: {
          ...state.psychicsReels,
          content: [] // Clear content on failure
        },
        error: action.payload
      };
    case FETCH_PSYCHICS_REELS_REFRESH_UPDATE:
      const reelObj = action.payload;
      const updatedList = state.psychicsReels.content.map((i) => {
        if (i.id == reelObj.id) {
          return reelObj;
        } else {
          return i;
        }
      });

      return {
        ...state,
        loading: false,
        psychicsReels: {
          ...state.psychicsReels,
          content: updatedList
        },
        error: action.payload
      };
    case FETCH_PSYCHICS_REELS_DELETE:
      const deleteObj = action.payload;
      const updatedDeleteList = state.psychicsReels.content.filter((i) => i.roomId !== deleteObj.roomId);
      return {
        ...state,
        loading: false,
        psychicsReels: {
          ...state.psychicsReels,
          content: updatedDeleteList
        },
        error: action.payload
      };
    case STORE_FILLTER_SETTING:
      state = {
        ...state,
        filterSetting: action.payload == null ? null : { ...action.payload }
      };
      return state;

    case STORE_FILLTER_SETTING_CLEAR:
      state = {
        ...state,
        filterSetting: null
      };
      return state;
    default:
      return state;
  }
};
