import { isSingleChat } from "./ChatHelper";
import { REACT_APP_CONTACT_SYNC, REACT_APP_XMPP_SOCKET_HOST } from "../../Components/processENV";
import { CHAT_TYPE_SINGLE, CHAT_TYPE_GROUP, MSG_RECEIVE_STATUS } from "./Constant";
import { getFormatPhoneNumber } from "../Utility";
import Store from "../../Store";
import { addNewRosterAction } from "../../Actions/RosterActions";
import { get as _get } from "lodash";
import SDK from "../../Components/SDK";
import { getFromLocalStorageAndDecrypt } from "../../Components/WebChat/WebChatEncryptDecrypt";
const requestUserids = [];

export const formatUserIdToJid = (userId, chatType = CHAT_TYPE_SINGLE) => {
  if (!userId || userId.includes(`${REACT_APP_XMPP_SOCKET_HOST}`)) return userId;
  return isSingleChat(chatType)
    ? `${userId}@${REACT_APP_XMPP_SOCKET_HOST}`
    : `${userId}@mix.${REACT_APP_XMPP_SOCKET_HOST}`;
};

export const formatGroupIdToJid = (groupId) => {
  if (!groupId || groupId.includes(`mix.${REACT_APP_XMPP_SOCKET_HOST}`)) return groupId;
  return formatUserIdToJid(groupId, CHAT_TYPE_GROUP);
};

export const isSingleChatJID = (jid) => {
  if (jid.includes(`mix`)) return false;
  return true;
};

/**
 * Return the ID(userId or groupId) from given jid(userJid or groupdJid)
 * @param {*} jid
 */
export const getIdFromJid = (jid) => {
  if (!jid) return jid;
  return jid.split("@")[0];
};

/**
 * Return the user Id from given message object based on the chat type
 * Onetoone chat userId will contain by fromUserId
 * Group chat userId will contain by publisherId
 * @param {*} msgObj
 */
export const getSenderIdFromMsgObj = (msgObj) => {
  if (!msgObj || typeof msgObj != "object") return "";
  const { fromUserId, publisherId } = msgObj;
  return isSingleChat(msgObj.chatType) ? fromUserId : publisherId;
};

export const getGroupNameFromRoster = (roster) => {
  if (!roster || typeof roster != "object") return roster;
  return roster.groupName || roster.groupId;
};

export const getContactNameFromRoster = (roster, fromConversation = false) => {
  if (!roster || typeof roster != "object") return roster;
  const { groupId, mobileNumber, name, nickName, isDeletedUser, userId, isFriend } = roster;
  if (userId === "123456789") {
    return "Admin";
  }
  if (groupId) {
    return getGroupNameFromRoster(roster);
  }
  if (isDeletedUser) {
    return getFormatPhoneNumber(userId);
  }
  if (!isFriend && !isDeletedUser && fromConversation) {
    return getFormatPhoneNumber(roster.userId);
  }
  const getMobileNumber = mobileNumber || userId;
  const profileType = getFromLocalStorageAndDecrypt("profileType");
  const displayname = formatRecentChatListDetails(roster, profileType);

  return displayname?.userName || getFormatPhoneNumber(getMobileNumber);
};

export const getContactNameFromRosterUpdate = (roster) => {
  if (!roster || typeof roster != "object") return roster;
  const { groupId, mobileNumber, name, nickName, isDeletedUser, userId, isFriend } = roster;
  if (userId === "123456789") {
    return {
      consolidatedName: "Admin",
      registeredName: ""
    };
  }
  if (groupId) {
    return {
      consolidatedName: getGroupNameFromRoster(roster),
      registeredName: ""
    };
  }
  return {
    consolidatedName: name || nickName || getFormatPhoneNumber(mobileNumber),
    registeredName: ""
  };
};

export const formatRecentChatListDetails = (roster, profileType) => {
  const { recentListType } = Store.getState().UserInformation;
  let userName,
    userImage = "";
  if (profileType === 1 && recentListType == "0") {
    userName = roster?.appUsername || roster?.nickName || roster?.name;
    userImage = roster?.image;
  } else {
    userName = profileType === 1 ? roster?.psychicUserName : roster?.appUsername || roster?.nickName || roster?.name;
    userImage = profileType === 1 ? roster?.psychicImageURL || roster?.psychicImageUrl : roster?.image;
  }
  return {
    userName,
    userImage
  };
};

export const initialNameHandle = (roster = {}, name = "") => {
  if (isLocalUser(roster.fromUser)) return name;
  let imageUrl = _get(roster, "image", "");
  const contactsWhoBlockedMe = Store.getState().contactsWhoBlockedMe.data;
  if (
    imageUrl === "" &&
    !roster.isAdminBlocked &&
    !roster.isDeletedUser &&
    !contactsWhoBlockedMe.indexOf(formatUserIdToJid(roster.userId)) > -1
  ) {
    return name;
  }
  return "";
};

export const capitalizeFirstLetter = (string) => {
  string = String(string);
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const arrayRoasterinitialNameHandle = (roster = [], name = "") => {
  roster.map((ele) => {
    if (_get(ele, "isFriend", false) !== false || _get(ele, "image", "") !== "") {
      return name;
    }
    return "";
  });
};

export const getUserInfoForSearch = (roster) => {
  if (!roster || typeof roster != "object") return roster;
  let fieldsForSearch = [];
  const userData = Store.getState().UserInformation;
  if (userData?.profileType == 1) {
    fieldsForSearch =
      userData?.recentListType == 0 ? ["appUsername", "nickName", "name"] : ["psychicAppUsername", "psychicUserName"];
  } else {
    fieldsForSearch = ["appUsername", "nickName", "name"];
  }
  const userInfo = [];
  fieldsForSearch.map((field) => roster[field] && userInfo.push(roster[field]));
  return userInfo;
};

/**
 * Check the given user is local or not
 * @param {*} userId
 */
export const isLocalUser = (userId = "") => {
  if (!userId) return false;
  userId = getIdFromJid(userId);
  const vCardData = Store.getState().vCardData;
  return userId === vCardData?.data?.userId;
};

export const getLocalUserDetails = (state) => {
  const stateObj = state || Store.getState();
  const vCardData = stateObj.vCardData;
  return vCardData?.data;
};

export const getFromUserIdFromVcard = (vcard) => {
  return vcard?.userId || vcard?.fromUser;
};

export const getDataFromRoster = (userId) => {
  if (isLocalUser(userId)) {
    const vCardData = Store.getState().vCardData;
    return vCardData?.data;
  }
  const currentState = Store.getState();
  let {
    rosterData: { rosterNames }
  } = currentState;
  if (rosterNames instanceof Map) {
    if (!rosterNames || !rosterNames.has(userId)) {
      if (userId) {
        getDataFromSDK(userId);
      }
      let data = { userId: userId, userJid: formatUserIdToJid(userId) };
      return data;
    } else {
      return rosterNames.get(userId);
    }
  }
  return {};
};

export const getDataFromSDK = async (userId) => {
  let data = { userId: userId, userJid: formatUserIdToJid(userId) };
  if (!requestUserids.includes(userId)) {
    requestUserids.push(userId);
    let blockedContactArr = Store.getState().blockedContact.data;
    const isBlockedUser = blockedContactArr.indexOf(data?.userJid) > -1;
    const profileDetailsResponse = isBlockedUser
      ? await SDK.getBlockedUsersProfile(userId)
      : await SDK.getUserProfile(formatUserIdToJid(userId));
    var index = requestUserids.indexOf(userId);
    if (index !== -1) {
      requestUserids.splice(index, 1);
    }
    if (profileDetailsResponse?.statusCode === 200) {
      let userProfileDetails = isBlockedUser ? profileDetailsResponse.data[0] : profileDetailsResponse.data;
      userProfileDetails.name = userProfileDetails.nickName;
      userProfileDetails.userId = userId;
      data = {
        ...data,
        ...userProfileDetails
      };
      addVcardDataToRoster(data);
    } else {
      // data = {
      //   ...data,
      //   isDeletedUser: true,
      //   email: "",
      //   image: "",
      //   thumbImage: "",
      //   isAdminBlocked: false,
      //   isFriend: false,
      //   mobileNumber: "",
      //   name: "Deleted User",
      //   nickName: "Deleted User",
      //   displayName: "Deleted User",
      //   status: ""
      // };
      // addVcardDataToRoster(data);
    }
    return data;
  }
  return data;
};

export const isUserExistInRoster = (userId) => {
  const currentState = Store.getState();
  const {
    rosterData: { rosterNames }
  } = currentState;
  return !!(rosterNames && rosterNames.has(userId));
};

export const formatDisplayName = (messageFrom = "") => {
  const userPhoneNumber = messageFrom.split("@")[0];
  const rosterObject = getDataFromRoster(messageFrom);
  if (!rosterObject) {
    return {
      nameToDisplay: getFormatPhoneNumber(userPhoneNumber),
      userColor: "black"
    };
  }
  const { userColor } = rosterObject || {};
  const nameToDisplay = getContactNameFromRoster(rosterObject);
  return {
    nameToDisplay,
    userColor
  };
};

export const addVcardDataToRoster = (userObj) => {
  if (!userObj || typeof userObj !== "object") return;
  if (userObj.userId && !userObj.userJid) {
    userObj["userJid"] = formatUserIdToJid(userObj.userId);
  }
  Store.dispatch(addNewRosterAction({ ...userObj, isFriend: false }));
};

export const getUserNickName = (userObj) => {
  return userObj?.nickName || userObj?.userProfile?.nickName;
};

export const isDisplayNickName = (userObj) => {
  return !isLocalUser(userObj.userId) && !userObj.isFriend && userObj.nickName;
};

export const getStructuredVcard = (vCardToUpdate) => {
  if (!vCardToUpdate || typeof vCardToUpdate !== "object" || Array.isArray(vCardToUpdate)) return vCardToUpdate;
  const defaultVcardObj = {
    userId: "",
    userJid: "",
    nickName: "",
    name: "",
    email: "",
    image: "",
    thumbImage: "",
    status: "",
    mobileNumber: "",
    isFriend: false
  };

  const finalObj = { ...defaultVcardObj, ...vCardToUpdate };
  if (finalObj.nickName && !finalObj.name) {
    finalObj.name = finalObj.nickName;
  }

  if (finalObj.name && !finalObj.nickName) {
    finalObj.nickName = finalObj.name;
  }

  if (finalObj.userId && !finalObj.userJid) {
    finalObj.userJid = formatUserIdToJid(finalObj.userId);
  }
  return finalObj;
};

export const isTheUserFriend = (userId) => {
  const currentState = Store.getState();
  const {
    rosterData: { rosterNames }
  } = currentState;
  if (rosterNames?.has(userId)) {
    return rosterNames.get(userId)?.isFriend;
  }
  return false;
};

export const setRosterDataFromMessage = (data, type) => {
  let dataToUpdate = null;
  if (type === MSG_RECEIVE_STATUS && data?.msgBody?.sender_name && isSingleChat(data?.chatType)) {
    const fromUserId = getSenderIdFromMsgObj(data);
    if (!isUserExistInRoster(fromUserId)) {
      dataToUpdate = {
        userId: fromUserId,
        nickName: data.msgBody.sender_name,
        name: data.msgBody.sender_name,
        image: data.msgBody.sender_image
      };
    }
  }

  if (dataToUpdate) {
    addVcardDataToRoster(dataToUpdate);
  }
};
export const setUserAsRoster = async (roster) => {
  if (roster?.userId && (!isUserExistInRoster(roster?.userId) || !isTheUserFriend(roster?.userId))) {
    const user = getStructuredVcard({ ...roster, isFriend: true });
    addVcardDataToRoster(user);
    SDK.setUserAsRoster(roster);
  }
};

export const getFriendsFromRosters = (rosters) => {
  if (!rosters || !Array.isArray(rosters)) return rosters;
  return rosters.filter((user) => user.isFriend);
};
export const getUserDetailsForMention = (userJid = "") => {
  let rosterData = {};
  let user = userJid.includes("@") ? userJid.split("@")[0] : userJid;
  let userDetails = getDataFromRoster(user);
  if (Object.keys(userDetails).length > 0) {
    rosterData.displayName = getContactNameFromRoster(userDetails);
  } else {
    rosterData.displayName = getFormatPhoneNumber(user);
  }
  return rosterData;
};

export const getUserDetails = (userJid = "") => {
  let rosterData = {};
  let user = userJid.includes("@") ? userJid.split("@")[0] : userJid;
  let vcardData = getLocalUserDetails() || {};
  if (user === vcardData?.userId) {
    rosterData.displayName = "You";
    rosterData.isFriend = true;
    rosterData.image = vcardData.image;
    rosterData.thumbImage = vcardData.thumbImage;
    rosterData.jid = user;
    rosterData.chatType = "chat";
    rosterData.initialName = vcardData.appUsername;
    rosterData.callInitiator = true;
  } else {
    let userDetails = getDataFromRoster(user);
    let { isAdminBlocked, image, thumbImage } = userDetails;

    if (Object.keys(userDetails).length > 0) {
      rosterData = userDetails;
      rosterData.displayName = getContactNameFromRoster(userDetails);
      rosterData.image = isAdminBlocked ? "" : image;
      rosterData.thumbImage = isAdminBlocked ? "" : thumbImage;
      rosterData.chatType = "chat";
      rosterData.initialName = isAdminBlocked ? "" : rosterData.displayName;
      rosterData.isAdminBlocked = isAdminBlocked;
      rosterData.callInitiator = false;
    } else {
      rosterData.displayName = getFormatPhoneNumber(user);
      rosterData.initialName = "";
      rosterData.image = "";
      rosterData.thumbImage = "";
      rosterData.jid = user;
      rosterData.chatType = "chat";
      rosterData.userColor = "";
      rosterData.userId = user;
      rosterData.userJid = formatUserIdToJid(user);
      rosterData.isAdminBlocked = isAdminBlocked;
      rosterData.callInitiator = false;
    }
  }
  return rosterData;
};

export const getLocalUserId = () => {
  let vcardData = getLocalUserDetails();
  const fromUserIdFromVcard = getFromUserIdFromVcard(vcardData);
  if (vcardData && Object.keys(vcardData).length) return fromUserIdFromVcard;
  return "";
};

export const handleMentionedUser = (text = "", mentionedUsersIds, mentionedMe, mentionedClass = "") => {
  let UserId = mentionedUsersIds;
  if (!text) return "";
  const pattern = /@\[\?\]/gi;
  if (
    mentionedUsersIds !== undefined &&
    text !== "" &&
    text.match(pattern) !== null &&
    text.match(pattern).length > 0 &&
    text.match(pattern) !== undefined
  ) {
    let content = text;
    let particiantData = [];
    particiantData = content.match(pattern);
    particiantData.map((uidPattern) => {
      const uid = uidPattern;
      for (let i = 0; i < UserId.length; i++) {
        const mentionedUserId = UserId[i];
        let rosterData = getUserDetailsForMention(mentionedUserId);
        let displayName = rosterData.displayName;
        content = `${content.replace(
          uid,
          `<button data-mentioned="${mentionedUserId}" class='${mentionedClass} ${
            mentionedMe === mentionedUserId ? " tagged  " : " "
          } mentioned'><b>@</b> <i>${displayName !== undefined ? displayName : []}</i> </button> `
        )}`;
      }
    });
    return content;
  } else {
    return text;
  }
};
