import { MUTE_STATUS } from "../Actions/Constants";
import uuidv4 from "uuid/v4";

const initialState = {
  id: uuidv4(),
  data: {
    muteStatus: 0
  },
  error: ""
};

export function getMuteStatusReducer(state = initialState, action = {}) {
  switch (action.type) {
    case MUTE_STATUS:
      return {
        ...state,
        data: action.payload,
        error: "",
        id: uuidv4()
      };
    default:
      return state;
  }
}
