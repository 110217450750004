export const updateRecall = (newMessage, messageArray = []) => {
  const combineData = [...messageArray?.consumerChat, ...messageArray?.psychicChat]?.map((messageObject) => {
    if (newMessage.msgId.includes(messageObject.msgId)) {
      // messageObject['deleteStatus'] = 1;
      messageObject = {
        ...messageObject,
        MessageType: "text",
        deleteStatus: 0,
        createdAt: "",
        msgbody: {
          message_type: "text",
          message: ""
        },
        msgType: newMessage.msgType,
        lastMsgId: newMessage.lastMsgId
      };
    }
    return messageObject;
  });
  return formatArrayData(combineData);
};

export const formatArrayData = (combineData) => {
  const initialData = {
    consumerChat: [],
    psychicChat: [],
    friendsChat: []
  };

  const formattedData = combineData.reduce((acc, val) => {
    const targetArray = acc[val?.conversationMessageType];
    if (targetArray) {
      targetArray.push(val);
    }
    return acc;
  }, initialData);
  return formattedData;
};

export const checkMessageStatus = (participants) => {
  const receiveToAll = participants.find((participant) => participant.msgStatus === 0);
  if (receiveToAll) {
    return 0;
  }
  const seenToAll = participants.find((participant) => participant.msgStatus === 1);
  if (seenToAll) {
    return 1;
  }
  return 2;
};

export const updateRecentStatus = (newMessage, messageArray = []) => {
  const { msgId, participants } = newMessage;
  return messageArray.map((messageObject) => {
    if (messageObject.msgId === msgId) {
      return {
        ...messageObject,
        status: checkMessageStatus(participants)
      };
    }
    return messageObject;
  });
};

export const emptyMessage = (newMessage, messageArray = []) => {
  const { msgIds = [] } = newMessage;
  const combineData = [...messageArray?.consumerChat, ...messageArray?.psychicChat]?.map((messageObject) => {
    if (msgIds.indexOf(messageObject.msgId) !== -1) {
      return {
        ...messageObject,
        MessageType: "text",
        deleteStatus: 0,
        createdAt: "",
        msgbody: {
          message_type: "text",
          message: ""
        },
        msgType: newMessage.msgType,
        lastMsgId: newMessage.lastMsgId
      };
    }
    return messageObject;
  });

  return formatArrayData(combineData);
};

export const expiredMessage = (newMessage, messageArray = []) => {
  const { msgIds = [] } = newMessage;
  const combineData = [...messageArray?.consumerChat, ...messageArray?.psychicChat]?.map((messageObject) => {
    if (msgIds.indexOf(messageObject.msgId) !== -1) {
      return {
        ...messageObject,
        isExpired: 1
      };
    }
    return messageObject;
  });

  return formatArrayData(combineData);
};
