import React from "react";
import { DateTime } from "luxon";
import * as moment from "moment";
import { get as _get, groupBy as _groupBy, cloneDeep as _cloneDeep } from "lodash";
import { REACT_APP_XMPP_SOCKET_HOST } from "../../Components/processENV";
import {
  blockOfflineAction,
  capitalizeFirstLetter,
  capitalizeTxt,
  fileToBlob,
  getArchiveSetting,
  getDbInstanceName,
  getFormatPhoneNumber,
  getLocalWebsettings,
  getMessageType,
  getUserIdFromJid,
  sendNotification,
  setLocalWebsettings
} from "../Utility";
import Store from "../../Store";
import {
  CHAT_TYPE_SINGLE,
  MSG_SENT_ACKNOWLEDGE_STATUS_ID,
  MSG_SENT_ACKNOWLEDGE_STATUS,
  MSG_DELIVERED_STATUS_ID,
  MSG_DELIVERED_STATUS,
  MSG_DELIVERED_STATUS_CARBON,
  MSG_SEEN_STATUS_ID,
  MSG_SEEN_STATUS,
  MSG_SEEN_STATUS_CARBON,
  MSG_PROCESSING_STATUS_ID,
  MSG_PROCESSING_STATUS,
  CHAT_TYPE_GROUP,
  GROUP_CHAT_PROFILE_UPDATED_NOTIFY,
  CHAT_TYPE_BROADCAST,
  BRAND_NAME,
  REPORT_FROM_CONTACT_INFO
} from "./Constant";
import {
  formatGroupIdToJid,
  formatUserIdToJid,
  getContactNameFromRoster,
  getSenderIdFromMsgObj,
  isLocalUser,
  getDataFromRoster,
  getUserDetails,
  getContactNameFromRosterUpdate,
  getFromUserIdFromVcard,
  getIdFromJid
} from "./User";
import { changeTimeFormat, msgStatusOrder } from "./RecentChat";
import { IconUploading } from "../../assets/images";
import { resetRecentChat, ActiveChatResetAction, updateArchiveStatusRecentChat } from "../../Actions/RecentChatActions";
import { ResetUnreadCount } from "../../Actions/UnreadCount";
import { GIF_FORMAT, GIF_STICKER_FORMATS, MEDIA_MESSAGE_TYPES, STICKER_FORMAT } from "../Constants";
import { DELETE_MESSAGE_FOR_EVERYONE, DELETE_MESSAGE_FOR_ME } from "../../Actions/Constants";
import IndexedDb from "../../Helpers/IndexedDb";
import { UpdateMediaUploadStatus } from "../../Actions/ChatHistory";
import { ReplyMessageAction } from "../../Actions/MessageActions";
import SDK from "../../Components/SDK";
import { getExtension } from "../../Components/WebChat/Common/FileUploadValidation";
import {
  getFromLocalStorageAndDecrypt,
  encryptAndStoreInLocalStorage,
  getFromSessionStorageAndDecrypt,
  encryptAndStoreInSessionStorage
} from "../../Components/WebChat/WebChatEncryptDecrypt";
import { getGroupData } from "./Group";
import { getAvatarImage, groupAvatar } from "../../Components/WebChat/Common/Avatarbase64";
import { webSettingLocalAction } from "../../Actions/BrowserAction";
import Config from "../../config";
import { DownloadingChatMedia } from "../../Actions/Media";
import ApiService from "../../Services/Api/ApiService";
import { useNavigate, useLocation } from "react-router-dom";
import { chatReadingCompeted } from "../../Components/Mixpanel";
import { getTrunOnOffChargeAction } from "../../Actions/TrunOnOffChargeAction";
import { getMuteStatusAction } from "../../Actions/MuteStatusAction";

const indexedDb = new IndexedDb();

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const { reportMembers } = Config;

export const notificationMessageType = ["1", "2", "3", "4", "5"];
export function formatBytes(a, b = 2) {
  if (0 === a) {
    return "0 Bytes";
  }
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
}
export function convertTime(sec) {
  var hours = Math.floor(sec / 3600);
  if (hours >= 1) sec = sec - hours * 3600;
  else hours = 0;
  var min = Math.floor(sec / 60);
  if (min >= 1) sec = sec - min * 60;
  else min = "00";
  if (sec < 1) sec = "00";
  else void 0;

  if (min.toString().length === 1) min = "0" + min;
  else void 0;
  if (sec.toString().length === 1) sec = "0" + sec;
  else void 0;
  return hours ? hours + ":" + min + ":" + sec : min + ":" + sec;
}
export const getJidFromRencentChat = (chatConversation) => {
  const { roster, recent } = chatConversation;
  const { jid, username, msgfrom } = roster || recent || {};
  const updateJid = jid || username || msgfrom;
  return updateJid + "@" + REACT_APP_XMPP_SOCKET_HOST;
};

export const displayNameFromRencentChat = (roster = {}) => {
  const { displayName, name, nickName, jid, groupName } = roster || {};
  return displayName || groupName || name || nickName || jid;
};

export const displayNameFromRoster = (roster, msgFrom) => {
  return getContactNameFromRoster(roster);
};

export const getNameFromGroup = (group) => {
  const { displayName } = group || {};
  return displayName || getContactNameFromRoster(group);
};

export const getDisplayNameFromGroup = (messageFrom = "", groupMemberDetails = []) => {
  const userId = messageFrom.split("@")[0];
  const rosterObject = getDataFromRoster(userId);
  if (!rosterObject) {
    const userDetails = getUserDetails(userId);
    if (userDetails && userDetails.nickName) {
      return {
        nameToDisplay: userDetails.nickName,
        userColor: "black"
      };
    }
    return {
      nameToDisplay: getFormatPhoneNumber(userId),
      userColor: "black"
    };
  }
  const { displayName, name, nickName, jid, userColor } = rosterObject || {};
  const nameObj = getContactNameFromRosterUpdate(rosterObject, true);
  let nameToDisplay =
    nameObj?.consolidatedName ||
    displayName ||
    name ||
    nickName ||
    jid ||
    getFormatPhoneNumber(messageFrom.split("@")[0]);

  return {
    nameToDisplay,
    userColor,
    registeredName: nameObj?.registeredName
  };
};

export const formatPhoneNumber = (jid) => {
  return getFormatPhoneNumber(jid);
};

export const getNamefromRoster = (rosterObject) => {
  const { displayName, name, nickName, jid } = rosterObject || {};
  return displayName || name || nickName || getFormatPhoneNumber(jid);
};

export const getNameAndStatus = (jid = "", roster = []) => {
  const userJid = jid.split("@")[0];
  return roster.find((profile) => {
    const rosterJid = profile.username ? profile.username : profile.userId;
    return rosterJid === userJid;
  });
};

export const getBroadCastUser = (participants = [], totalContactList = []) => {
  const rosterContacts = totalContactList.filter((singleContact) => {
    const rosterJid = singleContact.jid || singleContact.username;
    return participants.indexOf(rosterJid) !== -1;
  });

  return participants.map((groupMember) => ({
    ...rosterContacts.find((singleContact) => {
      const rosterJid = singleContact.jid || singleContact.username;
      if (rosterJid === groupMember) {
        return singleContact;
      }
    }),
    jid: groupMember
  }));
};

export const handleParticipantList = (participants = [], totalContactList = []) => {
  return participants.map((groupMember) => ({
    ...(getDataFromRoster(groupMember.userId) || {}),
    ...groupMember
  }));
};

export const classNames = (classes) => {
  return Object.entries(classes)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
    .join(" ");
};

export const groupBy = (messageToGroup, callback) => {
  return messageToGroup?.reduce((groups, message) => {
    const date = callback(message);
    groups[date] = [...(groups[date] || []), message];
    return groups;
  }, {});
};

export const groupByTime = (message, callback) => {
  const { res } = message.reduce(
    (messageGroup, singleImage, currentIndex, messageArray) => {
      const time = callback(singleImage);
      if (
        !messageGroup.currentTime ||
        messageGroup.currentTime - time > 60000 ||
        singleImage.msgtype !== messageGroup.messageType ||
        singleImage.fromUser !== messageGroup.fromUser
      ) {
        messageGroup.res = [...messageGroup.res, (messageGroup.currentArray = [])];
        messageGroup.fromUser = singleImage.fromUser;
        messageGroup.messageType = singleImage.msgtype;
        messageGroup.currentTime = time;
      }
      const previousObject = messageArray[currentIndex - 1];
      messageGroup.previousMessageUser =
        previousObject && previousObject.msgType !== GROUP_CHAT_PROFILE_UPDATED_NOTIFY
          ? getSenderIdFromMsgObj(previousObject)
          : null;
      messageGroup.currentArray.push({
        ...singleImage,
        previousMessageUser: messageGroup.previousMessageUser,
        ...(singleImage?.chatType === "groupchat" &&
          !singleImage.publisherId && { publisherId: singleImage.fromUserId })
      });
      return messageGroup;
    },
    { res: [] }
  );
  return res;
};

export const getCurrentTime = () => {
  return moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
};

const getlabel = (current_datetime) => {
  const today = moment();
  const dateObject = new Date(current_datetime.replace(/ /g, "T"));
  const diffenceDay = today.diff(current_datetime, "days");
  if (diffenceDay === 0) {
    return "Today";
  } else if (diffenceDay === 1) {
    return "Yesterday";
  } else if (diffenceDay > 1 && diffenceDay <= 6) {
    return DateTime.fromJSDate(dateObject).toFormat("cccc");
  } else if (dateObject.getFullYear() === new Date().getFullYear()) {
    return `${dateObject.getDate()} ${months[dateObject.getMonth()]}`;
  }
  return `${dateObject.getDate()} ${months[dateObject.getMonth()]} ${dateObject.getFullYear()}`;
};

export const convertDateFormat = (dateString) => {
  return getlabel(dateString);
};

export const deliveryStatus = {
  [MSG_SENT_ACKNOWLEDGE_STATUS_ID]: "offline",
  [MSG_DELIVERED_STATUS_ID]: "notsent",
  [MSG_SEEN_STATUS_ID]: "sent",
  [MSG_PROCESSING_STATUS_ID]: "processing"
};

export const isValidDeleteEveryOne = (msgdate) => {
  const date = new Date(msgdate.replace(/ /g, "T"));
  const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  const now = new Date();
  const validTime = 30 * 1000;
  if (now - newDate > validTime) {
    return false;
  }
  return true;
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const concatMedia = (activeData, stateData) => {
  const updateMedia = [...[...activeData].reverse(), ...stateData];
  return getUniqueListBy(updateMedia, "msgId");
};

export const concatMessageArray = (activeData, stateData, uniqueId, sortId) => {
  const updateMessage = [...stateData, ...activeData];
  return getUniqueListBy(updateMessage, uniqueId).sort((a, b) =>
    a[sortId] > b[sortId] ? 1 : a[sortId] < b[sortId] ? -1 : 0
  );
};

export const isSingleChat = (chatType) => chatType === CHAT_TYPE_SINGLE;
export const isGroupChat = (chatType) => chatType === CHAT_TYPE_GROUP;
export const isBroadcastChat = (chatType) => chatType === CHAT_TYPE_BROADCAST;
export const isSingleOrBroadcastChat = (chatType) => chatType === CHAT_TYPE_SINGLE || chatType === CHAT_TYPE_BROADCAST;
export const isSingleOrGroupChat = (chatType) => chatType === CHAT_TYPE_SINGLE || chatType === CHAT_TYPE_GROUP;

export const convertMsgStatusToStatusId = (statusStr) => {
  const msgStatusMap = {
    [MSG_DELIVERED_STATUS_CARBON]: MSG_DELIVERED_STATUS_ID,
    [MSG_DELIVERED_STATUS]: MSG_DELIVERED_STATUS_ID,
    [MSG_SEEN_STATUS]: MSG_SEEN_STATUS_ID,
    [MSG_SEEN_STATUS_CARBON]: MSG_SEEN_STATUS_ID,
    [MSG_SENT_ACKNOWLEDGE_STATUS]: MSG_SENT_ACKNOWLEDGE_STATUS_ID,
    [MSG_PROCESSING_STATUS]: MSG_PROCESSING_STATUS_ID
  };
  return msgStatusMap[statusStr];
};

/**
 * Could check here active conversation screen chat type by giving the chat type as argument
 * @param {Boolean} chatType
 */
export const activeConversationChatType = (chatType) => {
  const { activeChatData: { data: { recent } = {} } = {} } = Store.getState();
  if (!chatType || !recent) return false;
  return recent.chatType === chatType;
};

/**
 * Get the active conversation user ID
 */
export const getActiveConversationChatId = () => {
  const { activeChatData: { data: { chatId } = {} } = {} } = Store.getState();
  return chatId;
};

/**
 * Get the active conversation user ID
 */
export const getActiveConversationChatJid = () => {
  const { activeChatData: { data: { chatJid } = {} } = {} } = Store.getState();
  return chatJid;
};

/**
 * Get the active conversation user Jid
 */
export const getActiveConversationUserJid = () => {
  const { activeChatData: { data: { chatId, chatType } = {} } = {} } = Store.getState();
  return formatUserIdToJid(chatId, chatType);
};

/**
 * Get the active conversation group ID
 */
export const getActiveConversationGroupId = () => {
  const { activeChatData: { data: { roster, recent } = {} } = {} } = Store.getState();
  let data = roster && Object.keys(roster).length > 0 ? roster : recent;
  return (data && data.groupId) || (recent && recent.fromUserId);
};

/**
 * Get the active conversation group Jid
 */
export const getActiveConversationGroupJid = () => {
  const groupId = getActiveConversationGroupId();
  return formatGroupIdToJid(groupId);
};

export const getMyUserId = () => {
  const vCard = Store.getState().vCardData?.data;
  return vCard?.userId || undefined;
};

/**
 * Check the give USER or GROUP ID is in the active conversation screen
 * @param {string} userOrGroupId
 * @param {string} chatType
 */
export const isActiveConversationUserOrGroup = (userOrGroupId, chatType = CHAT_TYPE_SINGLE) => {
  if (!userOrGroupId) return false;
  const conversationUserOrGroupId = isSingleChat(chatType)
    ? getActiveConversationChatId()
    : getActiveConversationGroupId();
  userOrGroupId = getUserIdFromJid(userOrGroupId);
  return conversationUserOrGroupId === userOrGroupId;
};

/**
 * Return the user or group id based on active conversation
 */
export const getActiveConversationUserOrGroupId = () => {
  const isSingleChatActive = activeConversationChatType(CHAT_TYPE_SINGLE);
  if (isSingleChatActive) {
    return getActiveConversationChatId();
  }
  const isGroupChatActive = activeConversationChatType(CHAT_TYPE_GROUP);
  if (isGroupChatActive) {
    return getActiveConversationGroupId();
  }
  return undefined;
};

/**
 * Return the user or group JID based on active conversation
 */
export const getActiveConversationUserOrGroupJid = () => {
  const isSingleChatActive = activeConversationChatType(CHAT_TYPE_SINGLE);
  if (isSingleChatActive) {
    return getActiveConversationUserJid();
  }
  const isGroupChatActive = activeConversationChatType(CHAT_TYPE_GROUP);
  if (isGroupChatActive) {
    return getActiveConversationGroupJid();
  }
  return undefined;
};

/**
 * Return message details from active conversation array for given message Id
 */
export const getActiveConversationMessageByMsgId = (msgId, chatType) => {
  let message = undefined;
  let chatHistory = [];
  if (isSingleChat(chatType) || activeConversationChatType(CHAT_TYPE_SINGLE)) {
    chatHistory = Store.getState().singleChatMsgHistoryData?.data;
  }

  if (isGroupChat(chatType) || activeConversationChatType(CHAT_TYPE_GROUP)) {
    chatHistory = Store.getState().groupChatMessage?.data;
  }
  chatHistory = (chatHistory && Array.isArray(chatHistory) && chatHistory) || [];
  // Loop through from the recent messages that's why we loop through in backward
  // couldn't use reverse method, it's affected across the app
  for (let i = chatHistory.length - 1; i >= 0; i--) {
    if (chatHistory[i] && chatHistory[i].msgId === msgId) {
      message = chatHistory[i];
      break;
    }
  }
  return message;
};

/**
 * Check the message status in order by comparing the current & new status order index, then return the proper status
 * suppose If receive sent acknowledge after delivered status, then we sent delivered status.
 *
 * @param {*} currentStatus
 * @param {*} newStatus
 */
export const getMsgStatusInOrder = (currentStatus, newStatus) => {
  const currentStatusIndex = msgStatusOrder.indexOf(currentStatus);
  const newStatusIndex = msgStatusOrder.indexOf(newStatus);
  if (newStatusIndex > currentStatusIndex) {
    return newStatus;
  }
  return currentStatus;
};

/**
 * Chat conversation message status element
 * @param {*} status
 * @param {*} publisherId
 */
export const getMsgStatusEle = (status, publisherId) => {
  if (isLocalUser(publisherId)) {
    const updatedStatus = deliveryStatus[status];
    return (
      <span className={`status ${updatedStatus}`}>
        {status === MSG_PROCESSING_STATUS_ID && (
          <i className="uploading">
            <IconUploading />
          </i>
        )}
      </span>
    );
  }
  return null;
};

/**
 * Reseting Store Data
 */
export const resetStoreData = () => {
  Store.dispatch(ActiveChatResetAction());
  Store.dispatch(ResetUnreadCount());
  Store.dispatch(resetRecentChat());
};

export const updateSessionIdInLocalStorage = () => {
  let currentTabId = getFromSessionStorageAndDecrypt("sessionId");
  currentTabId != null && encryptAndStoreInLocalStorage("sessionId", currentTabId);
};

export const isCurrentSessionActive = () =>
  getFromSessionStorageAndDecrypt("sessionId") === null ||
  getFromLocalStorageAndDecrypt("sessionId") == null ||
  getFromLocalStorageAndDecrypt("sessionId") === getFromSessionStorageAndDecrypt("sessionId");

export const updateSessionId = (tabId) => {
  if (getFromSessionStorageAndDecrypt("sessionId") === null) {
    encryptAndStoreInSessionStorage("sessionId", tabId);
    encryptAndStoreInLocalStorage("sessionId", tabId);
  }
};

export const isTextMessage = (msgType) => {
  if (!msgType) return false;
  return ["text", "auto_text"].indexOf(msgType) > -1;
};

/**
 * Return the reply message format object from given message
 * @param {*} message
 */
export const getReplyMessageFormat = (message) => {
  if (!message) return {};
  return {
    chatType: message.chatType,
    deletedBy: message.deleteStatus,
    deletedStatus: message.deleteStatus,
    fromUserId: isSingleChat(message.chatType) ? message.fromUserId : message.publisherId,
    groupId: isGroupChat(message.chatType) ? message.fromUserId : undefined,
    oldMsgId: "",
    replyMsgContent: message.msgBody,
    replyMsgId: message.msgId
  };
};

export const isSameSession = () => {
  return getFromLocalStorageAndDecrypt("sessionId") === getFromSessionStorageAndDecrypt("sessionId");
};

export const updateFavicon = (type) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = type === "error" ? "../../favicon-error.png" : "../../favicon.png";
};

export const isMediaMessage = (msgBody = {}) => MEDIA_MESSAGE_TYPES.includes(msgBody.message_type);

export const arrayToObject = (arr, key) => {
  return arr.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

export const setTempMute = (name, stateData) => {
  const tempMuteUser = getFromLocalStorageAndDecrypt("tempMuteUser");
  let parserLocalStorage = tempMuteUser ? JSON.parse(tempMuteUser) : {};
  const constructObject = {
    ...parserLocalStorage,
    [name]: stateData
  };
  encryptAndStoreInLocalStorage("tempMuteUser", JSON.stringify(constructObject));
};
export const removeTempMute = (name) => {
  const tempMuteUser = getFromLocalStorageAndDecrypt("tempMuteUser");
  if (tempMuteUser) {
    let parserLocalStorage = JSON.parse(tempMuteUser);
    delete parserLocalStorage[name];
    encryptAndStoreInLocalStorage("tempMuteUser", JSON.stringify(parserLocalStorage));
  }
};

export const getChatHistoryData = (data, stateData) => {
  // To Avoid Unnecessary Looping, We are Using Key Value Pair for Chat and Messages
  // Eg: userId: {} or groupId: {} or msgId: {}
  const chatId = getUserIdFromJid(data.userJid || data.groupJid);
  const state = Object.keys(stateData).length > 0 ? stateData[chatId]?.messages || {} : {};
  const sortedData = concatMessageArray(data.data, Object.values(state), "msgId", "timestamp");
  const lastMessage = sortedData[sortedData.length - 1];
  let newSortedData;
  const localUserJid = getFromLocalStorageAndDecrypt("loggedInUserJidWithResource");
  const userId = localUserJid ? getUserIdFromJid(localUserJid) : "";
  if (userId === lastMessage?.publisherId) {
    newSortedData = sortedData.map((msg) => {
      msg.msgStatus = getMsgStatusInOrder(msg.msgStatus, lastMessage?.msgStatus);
      return msg;
    });
  } else newSortedData = sortedData;

  let isScrollNeeded = true;
  if (data.fetchLimit) {
    isScrollNeeded = data.data.length === data.fetchLimit; // To Check If this is the Last Message in the Chat, So no Scroll Fetch is Needed
  }
  const finalData = { isScrollNeeded, messages: arrayToObject(newSortedData, "msgId") };

  return {
    ...stateData,
    [chatId]: finalData
  };
};

export const clearChatHistoryOffline = (data, stateData) => {
  let chatData = stateData;
  const chatIds = Object.keys(chatData);
  const userId = data.fromUserId;
  const lastMessageId = data.lastMsgId;

  if (chatIds.includes(userId)) {
    let userOrGroupId = "";
    for (const chatId in chatData) {
      if (chatId === userId) {
        userOrGroupId = chatId;
      }
    }
    const chatMessages = chatData[userOrGroupId]?.messages;
    if (chatMessages[lastMessageId] !== undefined || "") {
      const timestamp = chatMessages[lastMessageId].timestamp;
      for (let msg in chatMessages) {
        if (data.favourite === "1") {
          if (chatMessages[msg].timestamp <= timestamp) {
            if (chatMessages[msg].favouriteStatus === 0) {
              delete chatMessages[msg];
            }
          }
        } else {
          if (chatMessages[msg].timestamp <= timestamp) {
            delete chatMessages[msg];
          }
        }
      }
      return chatMessages;
    }
  }
};

export const getChatHistoryMessagesData = () => {
  const { chatConversationHistory: { data } = {} } = Store.getState();
  return data;
};

export const getChatMessageHistoryById = (id) => {
  const { chatConversationHistory: { data = [] } = {} } = Store.getState();
  if (data[id]?.messages) return Object.values(data[id]?.messages);
  return [];
};

export const getUpdatedHistoryDataForReadingStatus = (data, stateData) => {
  const currentChatData = stateData[data.fromUserId];
  const msgIds = currentChatData?.messages ? Object.keys(currentChatData?.messages) : {};
  if (msgIds.length > 0) {
    const currentMessage = currentChatData.messages[data.msgId];
    if (currentMessage) {
      const { msgBody: { message_type } = {} } = currentMessage;
      if (!message_type) {
        return {
          ...stateData
        };
      }
      const msgStatus = data.readingChargeStatus;
      currentMessage.readingChargeStatus = msgStatus;

      return {
        ...stateData,
        [data.fromUserId]: {
          ...currentChatData,
          [data.msgId]: currentMessage
        }
      };
    }
  }
  return {
    ...stateData
  };
};
export const getUpdatedHistoryData = (data, stateData) => {
  // Here Get the Current Active Chat History and Active Message
  const currentChatData = stateData[data.fromUserId];
  const msgIds = currentChatData?.messages ? Object.keys(currentChatData?.messages) : {};

  if (msgIds.length > 0) {
    const currentMessage = currentChatData.messages[data.msgId];
    if (currentMessage) {
      const { msgBody: { message_type } = {} } = currentMessage;
      if (!message_type) {
        return {
          ...stateData
        };
      }
      const msgStatus = getMsgStatusInOrder(currentMessage.msgStatus, data.msgStatus);
      currentMessage.msgStatus = msgStatus;
      currentMessage.msgType = data.msgType;
      if (currentMessage?.msgBody?.media && data.msgType === "acknowledge") {
        currentMessage.msgBody.media.is_uploading = 2;
      }

      // Updating Old Msg Statuses to Current Status
      const currentMessageIndex = msgIds.indexOf(data.msgId);
      for (let i = 0; i < msgIds.length && i <= currentMessageIndex; i++) {
        const message = currentChatData?.messages[msgIds[i]];
        currentChatData.messages[msgIds[i]] = {
          ...message,
          ...(message.msgStatus !== 3 &&
            message.msgStatus !== 0 && {
              msgStatus: getMsgStatusInOrder(message.msgStatus, msgStatus)
            })
        };
      }

      return {
        ...stateData,
        [data.fromUserId]: {
          ...currentChatData,
          [data.msgId]: currentMessage
        }
      };
    }
  }
  return {
    ...stateData
  };
};

export const getUpdatedHistoryDataUpload = (data, stateData) => {
  // Here Get the Current Active Chat History and Active Message
  const currentChatData = stateData[data.fromUserId];
  if (currentChatData?.messages && Object.keys(currentChatData?.messages).length > 0) {
    const currentMessage = currentChatData.messages[data.msgId];

    if (currentMessage) {
      currentMessage.msgBody.media.is_uploading = data.uploadStatus;
      if (data.statusCode === 200 && !isGifStickerImage(currentMessage.msgBody.message_type)) {
        currentMessage.msgBody.media.file_url = data.fileToken || "";
        currentMessage.msgBody.media.thumb_image = data.thumbImage || "";
        currentMessage.msgBody.media.file_key = data.fileKey || "";
      }

      let msgIds = Object.keys(currentChatData?.messages);
      let nextIndex = msgIds.indexOf(data.msgId) + 1;
      let nextItem = msgIds[nextIndex];

      if (nextItem) {
        let nextMessage = currentChatData.messages[nextItem];
        if (nextMessage?.msgBody?.media?.is_uploading === 0) {
          nextMessage.msgBody.media.is_uploading = 1;

          return {
            ...stateData,
            [data.fromUserId]: {
              ...currentChatData,
              [data.msgId]: currentMessage,
              [nextItem]: nextMessage
            }
          };
        }
      }
      return {
        ...stateData,
        [data.fromUserId]: {
          ...currentChatData,
          [data.msgId]: currentMessage
        }
      };
    }
  }
  return {
    ...stateData
  };
};

export const updateDeletedMessageInHistory = (actionType, data, stateData) => {
  // Here Get the Current Active Chat History and Active Message
  const currentChatData = stateData[data.fromUserId];
  if (currentChatData) {
    if (actionType === DELETE_MESSAGE_FOR_ME) {
      data.msgIds.forEach((msgId) => delete currentChatData.messages[msgId]);
    } else if (actionType === DELETE_MESSAGE_FOR_EVERYONE) {
      const messageIds = data.msgId.split(",");
      for (let i = 0; i < messageIds.length; i++) {
        const msgId = messageIds[i];
        if (currentChatData.messages[msgId]) {
          currentChatData.messages[msgId].deleteStatus = 2;
          delete currentChatData.messages[msgId];
        }
      }
    }

    return {
      ...stateData,
      [data.fromUserId]: {
        ...currentChatData
      }
    };
  }
  return {
    ...stateData
  };
};

export const updateExpiredMessageInHistory = (data, stateData) => {
  let consumerChatData = stateData[data.consumer_user] || {};
  let psychicChatData = stateData[data.psychic_user] || {};
  if (consumerChatData && consumerChatData?.messages) {
    if (consumerChatData[data?.msgId]) {
      consumerChatData[data?.msgId].isExpired = 1;
    }
    if (consumerChatData.messages[data?.msgId]) {
      consumerChatData.messages[data?.msgId].isExpired = 1;
      return {
        ...stateData,
        [data.consumer_user]: {
          ...consumerChatData
        }
      };
    }
  }

  if (psychicChatData && psychicChatData?.messages) {
    if (psychicChatData[data?.msgId]) {
      psychicChatData[data?.msgId].isExpired = 1;
    }
    if (psychicChatData.messages[data?.msgId]) {
      psychicChatData.messages[data?.msgId].isExpired = 1;
      return {
        ...stateData,
        [data.consumer_user]: {
          ...psychicChatData
        }
      };
    }
  } else {
    return {
      ...stateData
    };
  }
};
export const updateCallLogMediaMessage = (data, stateData) => {
  const { psychic_user = "", consumer_user = "", message_id = "", replay_status = "" } = data;

  let verifyUserId;

  if (stateData[psychic_user]) {
    verifyUserId = psychic_user;
  } else if (stateData[consumer_user]) {
    verifyUserId = consumer_user;
  }

  if (!verifyUserId || !stateData[verifyUserId]) {
    return stateData;
  }

  // Create a deep copy of the state to avoid direct mutation
  let updatedState = { ...stateData, [verifyUserId]: { ...stateData[verifyUserId] } };

  if (!updatedState[verifyUserId].messages[message_id]) {
    return stateData;
  }

  updatedState[verifyUserId].messages = {
    ...updatedState[verifyUserId].messages,
    [message_id]: {
      ...updatedState[verifyUserId].messages[message_id],
      msgBody: {
        ...updatedState[verifyUserId].messages[message_id].msgBody,
        replay_status: replay_status
      }
    }
  };

  return updatedState;
};

export const callTypeLogString = {
  audio_appointment_call: "audio_appointment_call",
  video_appointment_call: "video_appointment_call",
  video_call: "video_call",
  audio_call: "audio_call"
};

export const callLogTypeBasedString = (msg_type) => {
  if (msg_type == callTypeLogString.video_appointment_call) {
    return {
      value: "Appt Video Call",
      condition: true
    };
  } else if (msg_type == callTypeLogString.audio_appointment_call) {
    return {
      value: "Appt Audio Call",
      condition: true
    };
  } else if (msg_type == callTypeLogString.video_call) {
    return {
      value: "Video Call",
      condition: true
    };
  } else if (msg_type == callTypeLogString.audio_call) {
    return {
      value: "Audio Call",
      condition: true
    };
  } else {
    return {
      value: "Call",
      condition: false
    };
  }
};

export const getActiveChatMessages = () => {
  const chatId = getActiveConversationChatId();
  const { chatConversationHistory: { data } = {} } = Store.getState();
  if (data[chatId]?.messages) return Object.values(data[chatId]?.messages);
  return [];
};

export const getLastMsgFromHistoryById = (chatId) => {
  const chatMessages = getChatMessageHistoryById(chatId);
  return chatMessages.length > 0 ? chatMessages[chatMessages.length - 1] : "";
};

export const getMessageFromHistoryById = (chatId, msgId) => {
  const { chatConversationHistory: { data } = {} } = Store.getState();
  if (data[chatId]?.messages && Object.keys(data[chatId]?.messages).length > 0) {
    return data[chatId]?.messages[msgId] || {};
  }
  return {};
};

export const getActiveChatUserRoster = () => {
  const { activeChatData: { data: { roster } = {} } = {} } = Store.getState();
  return roster;
};

export const updateMediaUploadStatus = (data, stateData) => {
  return stateData.map((el) => {
    if (el.msgId === data.msgId) {
      return {
        ...el,
        uploadStatus: 1
      };
    }
    return el;
  });
};

export const getActivePlan = async () => {
  const apiService = new ApiService();
  return await apiService.get({
    url: "/subscription/activeplan",
    onSuccess: (res) => {
      // return res?.data?.lastTransaction;
    },
    onError: (err) => {
      // return err?.message
    }
  });
};
export const resendAudioToSDK = async (
  file,
  jid,
  msgId,
  media,
  conversationType,
  readingType,
  psychicUser,
  cumulativeRate
) => {
  const {
    caption = "",
    mentionedUsersIds = [],
    fileDetails: { replyTo, audioType = "", videoType = "" } = {}
  } = file[0];
  const fileType = audioType ? "audio/mp3" : "video/mp4";
  const msgType = getMessageType(fileType, file);
  let fileOptions = {
    file: file,
    msgId: msgId,
    caption: caption,
    msgType
  };
  let response = {};
  const profileType = getFromLocalStorageAndDecrypt("profileType");
  let deduction_amount,
    subscribed_user = 0;
  if (profileType == 1 && (msgType === "audio" || msgType === "video")) {
    const balance = await getActivePlan();
    const rating = await SDK.getAdvisorRating(getIdFromJid(jid));
    const subRate =
      msgType === "audio" ? rating?.data?.subscriber_audio_reading_rate : rating?.data?.subscriber_video_reading_rate;
    const nonSubRate =
      msgType === "audio"
        ? rating?.data?.non_subscriber_audio_reading_rate
        : rating?.data?.non_subscriber_video_reading_rate;
    deduction_amount = balance?.data?.activePlan ? subRate : nonSubRate;
    subscribed_user = balance?.data?.activePlan ? 1 : 0;
    fileOptions.duration = file[0].fileDetails?.duration;
  }

  response = await SDK.sendFileMessage({
    toJid: jid,
    messageType: msgType === "audio" ? "audio_recorded_resend" : "video_recorded_resend",
    fileMessageParams: fileOptions,
    mentionedUsersIds: mentionedUsersIds,
    replyMessageId: replyTo,
    conversationType,
    readingType,
    psychicUser,
    profileType,
    deduction_amount: Number(deduction_amount).toFixed(2),
    subscribed_user,
    media,
    resend: true,
    cumulativeRate
  });
  let updateObj = {
    msgId,
    statusCode: response.statusCode,
    fromUserId: getUserIdFromJid(jid)
  };
  // Store.dispatch(UpdateMediaUploadStatus(updateObj));
};
export const handleCumulativeRate = (chatMessages) => {
  const data = chatMessages[chatMessages.length - 1];
  if (data?.fromUserJid !== data?.psychicUser && data?.isExpired == "0") {
    return data.msgBody.cumulative_rate || "0";
  } else {
    return "0";
  }
};

export const handleChatReading = ({ chatMessages, currentUserId, activeChat }) => {
  if (chatMessages.fromUserJid !== chatMessages?.psychicUser && chatMessages?.isExpired == "0") {
    chatReadingCompeted({
      reading_type: chatMessages.readingType == "1" ? "Paid" : "Free",
      reading_revenue: chatMessages.msgBody.cumulative_rate
        ? Number(chatMessages.msgBody.cumulative_rate).toFixed(2)
        : 0,
      reading_date: new Date().toISOString(),
      reading_id: chatMessages.msgId,
      reading_rate: chatMessages.perMessageCharacterLimit || "",
      advisor_id: currentUserId,
      reading_origin: "Chat view"
    });
  }
};
export const uploadFileToSDK = async (file, jid, msgId, media, conversationType, readingType, psychicUser, props) => {
  let cumulativeRate = await handleCumulativeRate(props.chatmessages);
  const { activeChatData } = Store.getState();
  const { caption = "", mentionedUsersIds = [], fileDetails: { replyTo, audioType = "", videoType = "" } = {} } = file;
  const fileType = SDK.getFileTypeByFileInstance(file);
  const msgType = getMessageType(fileType, file);
  let fileOptions = {
    file: file,
    msgId: msgId,
    caption: caption,
    duration: Math.round(file.fileDetails?.duration / 1000)
  };
  let response = {};
  const profileType = getFromLocalStorageAndDecrypt("profileType");
  let deduction_amount,
    subscribed_user = 0;
  if (profileType == 1 && (msgType === "audio" || msgType === "video")) {
    const balance = await getActivePlan();
    const rating = await SDK.getAdvisorRating(getIdFromJid(jid));
    const subRate =
      msgType === "audio" ? rating?.data?.subscriber_audio_reading_rate : rating?.data?.subscriber_video_reading_rate;
    const nonSubRate =
      msgType === "audio"
        ? rating?.data?.non_subscriber_audio_reading_rate
        : rating?.data?.non_subscriber_video_reading_rate;
    deduction_amount = balance?.data?.activePlan ? subRate : nonSubRate;
    subscribed_user = balance?.data?.activePlan ? 1 : 0;
  }
  cumulativeRate = (Number(cumulativeRate) + Number(deduction_amount)).toString();

  if (msgType === "file") {
    // response = await SDK.sendDocumentMessage(jid, file, fileOptions, replyTo, mentionedUsersIds);
    response = await SDK.sendFileMessage({
      toJid: jid,
      messageType: "file",
      fileMessageParams: fileOptions,
      mentionedUsersIds: mentionedUsersIds,
      replyMessageId: replyTo,
      conversationType,
      readingType,
      psychicUser,
      profileType,
      cumulativeRate
    });
  } else if (msgType === "image") {
    // response = await SDK.sendImageMessage(jid, file, fileOptions, replyTo, mentionedUsersIds);
    response = await SDK.sendFileMessage({
      toJid: jid,
      messageType: "image",
      fileMessageParams: fileOptions,
      mentionedUsersIds: mentionedUsersIds,
      replyMessageId: replyTo,
      conversationType,
      readingType,
      psychicUser,
      profileType,
      cumulativeRate
    });
  } else if (msgType === "video") {
    //response = await SDK.sendVideoMessage(jid, file, fileOptions, replyTo, mentionedUsersIds);
    response = await SDK.sendFileMessage({
      toJid: jid,
      messageType: videoType === "recording" ? "video_recorded" : "video",
      fileMessageParams: fileOptions,
      mentionedUsersIds: mentionedUsersIds,
      replyMessageId: replyTo,
      conversationType,
      readingType,
      psychicUser,
      profileType,
      deduction_amount: Number(deduction_amount).toFixed(2),
      subscribed_user,
      cumulativeRate
    });
  } else if (msgType === "audio") {
    // response = await SDK.sendAudioMessage(jid, file, fileOptions, replyTo, mentionedUsersIds);
    response = await SDK.sendFileMessage({
      toJid: jid,
      messageType: audioType === "recording" ? "audio_recorded" : "audio",
      fileMessageParams: fileOptions,
      mentionedUsersIds: mentionedUsersIds,
      replyMessageId: replyTo,
      conversationType,
      readingType,
      psychicUser,
      profileType,
      deduction_amount: Number(deduction_amount).toFixed(2),
      subscribed_user,
      cumulativeRate
    });
  } else if (GIF_STICKER_FORMATS.includes(msgType)) {
    // response = await SDK.sendGifMessage(jid, file, fileOptions, replyTo, mentionedUsersIds);
    const clonedFile = _cloneDeep(file);
    const { fileDetails, ...restFile } = clonedFile;
    const { fileId, imageUrl, msgType, replyTo, ...restFileDetails } = fileDetails;
    const fileToSend = {
      ...restFile,
      fileDetails: { fileId, imageUrl, msgType, replyTo }
    };

    fileOptions.file = fileToSend;
    const fileType = SDK.getFileTypeByFileInstance(fileToSend);
    fileOptions["options"] = restFileDetails;
    response = await SDK.sendFileMessage({
      toJid: jid,
      messageType: fileType,
      fileMessageParams: fileOptions,
      mentionedUsersIds: mentionedUsersIds,
      replyMessageId: replyTo,
      conversationType,
      readingType,
      psychicUser,
      profileType
    });
  }

  let updateObj = {
    msgId,
    statusCode: response.statusCode,
    fromUserId: getUserIdFromJid(jid)
  };

  if (response.statusCode === 200) {
    // mixpanel psychic reply
    if (profileType === 2 && conversationType === 1) {
      handleChatReading({
        chatMessages: props.chatmessages[props.chatmessages?.length - 2] || [],
        currentUserId: props.vCardData.userId,
        activeChat: activeChatData
      });
    }

    if (msgType === "image" || msgType === "audio") {
      const fileBlob = await fileToBlob(file);
      indexedDb.setImage(response.data.msgBody.media.fileToken, fileBlob, getDbInstanceName(msgType));
    }

    if (!GIF_STICKER_FORMATS.includes(msgType)) {
      updateObj.fileToken = response.data.msgBody.media.fileToken;
      updateObj.thumbImage = response.data.msgBody.media.thumbImage;
      updateObj.fileKey = response.data.msgBody.media.file_key;
    }
  } else if (response.statusCode === 500) {
    updateObj.uploadStatus = 3;
  }
  Store.dispatch(UpdateMediaUploadStatus(updateObj));
};

export const updateMediaUploadStatusHistory = (data, stateData) => {
  // Here Get the Current Active Chat History and Active Message
  const currentChatData = stateData[data.fromUserId];
  if (currentChatData?.messages && Object.keys(currentChatData?.messages).length > 0) {
    const currentMessage = currentChatData.messages[data.msgId];
    if (currentMessage) {
      currentMessage.msgBody.media.is_uploading = data.uploadStatus;
      return {
        ...stateData,
        [data.fromUserId]: {
          ...currentChatData,
          [data.msgId]: currentMessage
        }
      };
    }
  }
  return {
    ...stateData
  };
};

export const updateUploadStatusHistory = (data, stateData) => {
  // Here Get the Current Active Chat History and Active Message
  const currentChatData = stateData[data.fromUserId];
  if (currentChatData?.messages && Object.keys(currentChatData?.messages).length > 0) {
    const currentMessage = currentChatData.messages[data.msgId];
    if (currentMessage) {
      currentMessage.uploadStatus = data.uploadStatus;
      currentMessage.is_uploading = data.uploadStatus;
      return {
        ...stateData,
        [data.fromUserId]: {
          ...currentChatData,
          [data.msgId]: currentMessage
        }
      };
    }
  }
  return {
    ...stateData
  };
};

const getFavaouriteBy = (message, data, vcardData) => {
  if (!message || !data) return "";
  const currentFavouriteStatus = data.favouriteStatus;
  if (!vcardData) return "";
  const fromUserIdFromVcard = getFromUserIdFromVcard(vcardData);
  if (currentFavouriteStatus === 1) {
    return fromUserIdFromVcard;
  }

  if (currentFavouriteStatus === 0) {
    return "";
  }
};
export const updateFavouriteStatusHistory = (data, stateData, vcardData) => {
  const currentChatData = stateData[data.fromUserId];
  if (currentChatData) {
    if (data.msgId) {
      const message = currentChatData?.messages[data.msgId];
      currentChatData.messages[data.msgId] = {
        ...message,
        favouriteStatus: data.favouriteStatus,
        favouriteBy: getFavaouriteBy(message, data, vcardData)
      };
    } else if (data.msgIds && data.msgIds.length) {
      for (let i = 0; i < data.msgIds.length; i++) {
        const message = currentChatData?.messages[data.msgIds[i]];
        currentChatData.messages[data.msgIds[i]] = {
          ...message,
          favouriteStatus: data.favouriteStatus,
          favouriteBy: getFavaouriteBy(message, data, vcardData)
        };
      }
    }
    return {
      ...stateData,
      [data.fromUserId]: {
        ...currentChatData
      }
    };
  }
  return {
    ...stateData
  };
};

export const removeFavouriteStatusHistory = (data, stateData) => {
  // Here We are Removing Starred Status in Conversation History of Each Opened Chat
  const duplicateState = JSON.parse(JSON.stringify(stateData));
  const groupByResult = _groupBy(data, "fromUserId");
  const chatIds = Object.keys(groupByResult);
  for (let i = 0; i < chatIds.length; i++) {
    const currentChat = stateData[chatIds[i]];
    if (currentChat) {
      const unStarredMessages = groupByResult[chatIds[i]];
      const msgIds = unStarredMessages.map((msg) => msg.msgId);
      for (let j = 0; j < msgIds.length; j++) {
        const currentMsg = currentChat.messages[msgIds[j]];
        if (currentMsg) {
          duplicateState[chatIds[i]].messages[msgIds[j]] = { ...currentMsg, favouriteStatus: 0, favouriteBy: "" };
        }
      }
    }
  }
  return {
    ...duplicateState
  };
};

export const offlineReplyHandle = (newObj = {}, replyTo = "") => {
  const dataObj = {
    oldMsgId: "",
    deletedBy: "0",
    replyMsgId: replyTo,
    chatType: _get(newObj, "chatType", ""),
    groupId: _get(newObj, "fromUserId", ""),
    replyMsgContent: _get(newObj, "msgBody", {}),
    deleteStatus: _get(newObj, "deleteStatus", ""),
    fromUserId:
      _get(newObj, "chatType", "") === "groupchat" ? _get(newObj, "publisherId", "") : _get(newObj, "fromUserId", "")
  };
  Store.dispatch(ReplyMessageAction(dataObj));
};

export const getDownloadFileName = (file_url, msgType) => {
  const time = `${moment().format("YYYY-MM-DD")} at ${moment().format("hh.mm.ss A")}`;
  const extension = getExtension(file_url);
  const fileType = msgType === "audio" ? "audio" : msgType;
  return `${BRAND_NAME} ${capitalizeFirstLetter(fileType)} ${time}${extension}`;
};

export const downloadMediaFile = async (msgId, file_url, msgType, file_name, fileKey, event) => {
  if (blockOfflineAction()) return;

  Store.dispatch(
    DownloadingChatMedia({
      downloadMediaMsgId: msgId,
      downloadingFile: file_url,
      downloading: true,
      downloadingMediaType: msgType
    })
  );
  if (file_url) {
    const fileName = msgType === "file" ? file_name : getDownloadFileName(file_url, msgType);
    let fileUrl = file_url;
    // For Downloading Live File with Original Name
    if (file_url.search("blob:") === -1) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      const mediaResponse = await SDK.getMediaURL(file_url, fileKey, msgId);
      if (mediaResponse.statusCode === 200) {
        Store.dispatch(
          DownloadingChatMedia({
            downloadMediaMsgId: msgId,
            downloadingFile: file_url,
            downloading: false,
            downloadingMediaType: msgType
          })
        );
        fileUrl = mediaResponse.data.blobUrl;
      } else {
        console.log("error in downloading media file");
        return;
      }
    }
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = fileUrl;
    anchor.setAttribute("download", fileName);
    document.body.appendChild(anchor);
    anchor.click();
    window.URL.revokeObjectURL(anchor.href);
    document.body.removeChild(anchor);
  }
};

export const updateStarredList = (data, stateData = [], originalMsg = {}) => {
  const newState = [...stateData];
  const index = stateData.findIndex((el) => el.msgId === data.msgId);
  if (index > -1) {
    if (data.favouriteStatus === 0) newState.splice(index, 1);
  } else if (data.favouriteStatus === 1) {
    newState.push(originalMsg);
  }

  return newState;
};

export const getFavaouriteMsgObj = (msg, chatId) => {
  return {
    ...msg,
    favDate: changeTimeFormat(Date.now() * 1000),
    msgType: msg?.msgBody?.message_type,
    ...(isSingleChat(msg.chatType) && {
      publisherId: msg.publisherId,
      publisherJid: formatUserIdToJid(msg.publisherId),
      fromUserId: chatId,
      fromUserJid: formatUserIdToJid(chatId)
    }),
    ...(isGroupChat(msg.chatType) && {
      publisherId: msg.publisherId,
      publisherJid: formatUserIdToJid(msg.publisherId),
      fromUserId: chatId,
      fromUserJid: formatUserIdToJid(chatId, msg.chatType)
    })
  };
};

export const getBlobUrlFromToken = (fileToken, dbInstance, fileKey) => {
  return indexedDb
    .getImageByKey(fileToken, dbInstance, fileKey)
    .then((blob) => window.URL.createObjectURL(blob))
    .catch(() => "");
};

export const updateMessageTranslate = (data, stateData) => {
  const currentChatData = stateData[data.fromUserId];
  if (currentChatData?.messages && Object.keys(currentChatData?.messages).length > 0) {
    const currentMessage = currentChatData.messages[data.msgId];
    if (currentMessage) {
      currentMessage.msgBody.translatedMessage = data.translatedMessage;
      return {
        ...stateData,
        [data.fromUserId]: {
          ...currentChatData,
          [data.msgId]: currentMessage
        }
      };
    }
  }
  return {
    ...stateData
  };
};

export const getAllStarredMessages = () => {
  const { starredMessages: { data = [] } = {} } = Store.getState();
  return data;
};

export const updatedStarredMessageStatus = (data, stateData = []) => {
  return stateData.map((msg) => {
    if (msg.msgId === data.msgId) {
      msg.msgStatus = getMsgStatusInOrder(msg.msgStatus, data.msgStatus);
    }
    return msg;
  });
};
export const getRecentChatListTab = () => {
  const { UserInformation: { profileType, recentListType } = {} } = Store.getState();
  return profileType === 1 && recentListType == "0"
    ? "friendsChat"
    : profileType === 1
      ? "consumerChat"
      : "psychicChat";
};
export const getMutedChats = () => {
  const { recentChatData: { data = [] } = {} } = Store.getState();
  const chatIds = [];
  const type = getRecentChatListTab();
  const list = data[type] || [];
  list.forEach((recent) => {
    if (recent.muteStatus === 1) chatIds.push(recent.fromUserId);
  });
  return chatIds;
};

export const getArchivedChats = () => {
  const { recentChatData: { data = [] } = {} } = Store.getState();
  const chatIds = [];
  const type = getRecentChatListTab();
  const list = data[type] || [];
  list?.forEach((recent) => {
    if (recent.archiveStatus === 1) chatIds.push(recent.fromUserId);
  });
  return chatIds;
};

export const getPushNotificationData = async (data) => {
  let title = "",
    imageToken = "",
    updateMessage = "";
  const { fromUserId = "", publisherId = "", chatType = "", msgBody: { message = "", message_type = "" } = {} } = data;

  try {
    updateMessage = isTextMessage(message_type) ? message : `${capitalizeTxt(message_type)}`;

    if (isSingleChat(chatType)) {
      const roster = getUserDetails(fromUserId) || {};
      const { displayName, image, thumbImage } = roster;
      title = displayName || "";
      imageToken = thumbImage || image;
    } else {
      const groupData = getGroupData(fromUserId) || {};
      const { groupName, groupImage, thumbImage } = groupData;
      title = groupName || "";
      imageToken = thumbImage || groupImage;
      const senderName = getContactNameFromRoster(getDataFromRoster(publisherId)) || publisherId;
      updateMessage = `${senderName}: ${updateMessage}`;
    }

    const result = await indexedDb.getImageByKey(imageToken, "profileimages");
    return {
      title,
      image: window.URL.createObjectURL(result),
      message: updateMessage
    };
  } catch (error) {
    return {
      title,
      image: isSingleChat(chatType) ? getAvatarImage : groupAvatar,
      message: updateMessage
    };
  }
};

export const handleUnarchiveChat = (fromUserId, chatType = CHAT_TYPE_SINGLE) => {
  SDK.updateArchiveChat(formatUserIdToJid(fromUserId, chatType), false);
  const dispatchData = {
    fromUserId,
    isArchived: false
  };
  Store.dispatch(updateArchiveStatusRecentChat(dispatchData));
};

export const handleArchiveActions = async (listenerData = {}) => {
  const { msgType = "", fromUserId = "", chatType = "", msgBody: { replyTo = "" } = {} } = listenerData;
  if (msgType === "carbonReceiveMessage" || msgType === "receiveMessage") {
    const chatIds = getArchivedChats(),
      isPermanentArchvie = getArchiveSetting();

    // UnArchive Chat On Receiving New Message - Only for Temporary Archive
    if (fromUserId && chatIds.includes(fromUserId) && !isPermanentArchvie) {
      handleUnarchiveChat(fromUserId, chatType);
    }

    // Send Push If Message is Replied to Current User's Message
    if (replyTo) {
      const mutedChats = getMutedChats();
      if (isGroupChat(chatType) && !isActiveConversationUserOrGroup(fromUserId) && !mutedChats.includes(fromUserId)) {
        const orgMsg = await SDK.getMessageById(replyTo);
        if (orgMsg.statusCode === 200 && orgMsg.data) {
          const { publisherId = "" } = orgMsg.data || {};
          if (isLocalUser(publisherId)) {
            const { title, image, message } = await getPushNotificationData(listenerData);
            sendNotification(title, image, message, fromUserId);
          }
        }
      }
    }
  }
};

export const handleTempArchivedChats = (chatJid, chatType) => {
  const archivedChats = getArchivedChats(),
    isPermanentArchvie = getArchiveSetting(),
    chatId = getUserIdFromJid(chatJid);

  if (chatId && archivedChats.includes(chatId) && !isPermanentArchvie) {
    handleUnarchiveChat(chatId, chatType);
  }
};

export const handleUserSettings = async () => {
  const userSettings = await SDK.getUserSettings();
  const webSettings = getLocalWebsettings() || {};
  Store.dispatch(
    webSettingLocalAction({
      isEnableArchived: userSettings?.data?.archive === 0 ? false : true,
      translate: webSettings.translate || false
    })
  );
  setLocalWebsettings("archive", userSettings?.data?.archive === 0 ? false : true);
};

export const handleArchivedChats = async () => {
  const archivedChats = await SDK.getAllArchivedChats();
  if (archivedChats.statusCode === 200 && archivedChats.data.length) {
    for (let i = 0; i < archivedChats.data.length; i++) {
      const element = archivedChats.data[i];
      element.isArchived = true;
      Store.dispatch(updateArchiveStatusRecentChat(element));
    }
  }
};

export const getRecentChatData = () => {
  const { recentChatData: { data = [] } = {} } = Store.getState();
  return data;
};

export const getMessagesForReport = (chatId, selectedMsgIdType) => {
  const chatMessages = getChatMessageHistoryById(chatId);
  let msgDataArray = [];
  const sortedData = chatMessages
    .filter((item) => Object.keys(item.msgBody).length !== 0)
    .sort((a, b) => (b.timestamp < a.timestamp ? -1 : 1));
  if (sortedData.length) {
    let selectedMsgId = selectedMsgIdType !== REPORT_FROM_CONTACT_INFO ? selectedMsgIdType : sortedData[0]?.msgId;
    let count = 0;
    const filteredData = sortedData.filter((msg) => {
      if (msg.msgId === selectedMsgId || (count > 0 && count < reportMembers)) {
        count++;
        return msg;
      }
    });
    filteredData.map((item, key) => {
      let msgObject = {};
      msgObject["msgId"] = item?.msgId || "";
      msgObject["message"] = item?.msgBody?.message || item?.msgBody?.media?.caption || "";
      msgObject["msgType"] = item?.msgBody?.message_type || "";
      msgObject["filename"] = item?.msgBody?.media?.fileName || "";
      msgObject["timestamp"] = item?.timestamp;
      msgObject["publisherId"] = item?.publisherId || item?.fromUserId;
      msgDataArray.push(msgObject);
    });
  } else if (sortedData.length === 0) {
    return msgDataArray;
  }
  return msgDataArray;
};

export const convertMeridiemTextToSmall = (text) => {
  if (!text || typeof text !== "string") return text;
  return text.replace("AM", "am").replace("PM", "pm");
};

export const getBase64Image = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const isGifImage = (message_type) => message_type === GIF_FORMAT;
export const isStickerImage = (message_type) => message_type === STICKER_FORMAT;
export const isGifStickerImage = (message_type) => {
  if (!message_type) return false;
  return GIF_STICKER_FORMATS.includes(message_type);
};

export const calWidthHeightRatio = (originalWidth, originalHeight, maxDimension) => {
  const newDimension = {
    width: originalWidth,
    height: originalHeight
  };

  if (originalWidth >= originalHeight && originalWidth >= maxDimension) {
    newDimension.width = maxDimension;
    newDimension.height = Math.round((originalHeight / originalWidth) * maxDimension);
  } else if (originalHeight >= originalWidth && originalHeight >= maxDimension) {
    newDimension.height = maxDimension;
    newDimension.width = Math.round((originalWidth / originalHeight) * maxDimension);
  }
  return newDimension;
};

export const pluralize = (count, noun, suffix = "s") => `${count} ${noun}${count !== 1 ? suffix : ""}`;

export const handleReadingType = async (jid) => {
  const {
    UserInformation: { profileType } = {},
    turnOnOffCharge: { data }
  } = Store.getState();
  if (profileType === 1) {
    return data?.readingChargeAsPsychic === "ACCEPTED" ? 1 : 2;
  }
  if (profileType === 2) {
    return data?.readingChargeAsConsumer === "ACCEPTED" ? 1 : 2;
  }
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const navigate = useNavigate();
    const location = useLocation();
    return <Component {...props} location={location} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

export const getTurnOnChargingAsConsumer = (turnOnOffCharge) => {
  return turnOnOffCharge.readingChargeAsConsumer === "ACCEPTED" ||
    turnOnOffCharge.readingChargeAsConsumer === "PENDING" ||
    turnOnOffCharge.readingChargeAsConsumer === "REJECTED"
    ? false
    : true;
};

export const getTurnOnOffCharge = async () => {
  const { activeChatData } = Store.getState();
  const res = await SDK.getTurnOnOffCharge(activeChatData?.data?.chatJid);
  Store.dispatch(getTrunOnOffChargeAction(res.data));
};

export const getMuteStatus = async (userId, conversationType) => {
  const res = await SDK.getMuteStatus(userId, conversationType);
  if (res && res.data) {
    Store.dispatch(getMuteStatusAction({ ...res.data, muteStatus: +(res.data.muteStatus ?? 0) }));
  }
  return res;
};
