import { CALL_TALKED_TIMER_UPDATE } from "../Actions/Constants";

const initialState = {
  id: null,
  data: {
    timer: 0
  }
};

export function callTimerReducer(state = initialState, action = {}) {
  if (action.type === CALL_TALKED_TIMER_UPDATE) {
    state.id = action.payload.id;
    state.data = { ...initialState.data, ...state.data, ...action.payload?.data };
    return { ...state };
  }
  return state;
}
