export const ALLOWED_IMAGE_VIDEO_FORMATS = ["png", "jpeg", "jpg", "mp4", "avi", "mov", "ogg", "webm", "wmv", "ogv"];
export const ALLOWED_IMAGE_FORMATS = ["png", "jpeg", "jpg"];
export const ALLOWED_VIDEO_FORMATS = ["mp4"];
export const ALLOWED_AUDIO_FORMATS = ["mp3", "aac", "wav"];
export const AUDIO_VIDEO_FORMATS = ["mp3", "aac", "wav", "mp4", "avi", "mov", "ogg", "webm", "wmv", "ogv"];
export const ALLOWED_DOCUMENT_FORMATS = [
  "doc",
  "docx",
  "pdf",
  "xls",
  "xlsx",
  "hex",
  "txt",
  "csv",
  "ppt",
  "pptx",
  "zip",
  "rar"
];

export const IMAGE_VIDEO_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "video/mp4",
  "video/x-msvideo",
  "video/ogg"
];
export const IMAGE_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
export const AUDIO_FORMATS = ["audio/mp3", "audio/wav", "audio/mpeg", "audio/ogg", "audio/aac", "audio/vnd.dlna.adts"];
export const VIDEO_FORMATS = ["video/mp4", "video/webm", "video/x-msvideo", "video/ogg"];
export const GIF_FORMAT = "gif";
export const STICKER_FORMAT = "sticker";
export const GIF_STICKER_FORMATS = [GIF_FORMAT, STICKER_FORMAT];
export const DOCUMENT_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "text/plain",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/zip",
  "application/x-zip-compressed",
  "application/rar",
  "application/x-rar-compressed",
  "application/vnd.rar",
  "application/excel",
  "application/x-excel",
  "application/x-msexcel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
];
export const ALLOWED_ALL_FILE_FORMATS = [
  "jpg",
  "jpeg",
  "png",
  "mp3",
  "wav",
  "aac",
  "mpeg",
  "mp4",
  "ogg",
  "webm",
  "doc",
  "docx",
  "pdf",
  "xls",
  "xlsx",
  "txt",
  "csv",
  "ppt",
  "zip",
  "rar",
  "pptx",
  "acc",
  "hex",
  ...GIF_STICKER_FORMATS
];
export const FEATURE_RESTRICTION_ERROR_MESSAGE = "Feature unavailable for your plan";
export const CHAT_IMAGES = "chatimages";
export const CHAT_AUDIOS = "chataudios";

export const MAX_WIDTH_WEB = 330;
export const MIN_WIDTH_WEB = 240;
export const MAX_HEIGHT_WEB = 338;
export const MIN_HEIGHT_WEB = 83;

export const MAX_WIDTH_AND = 250;
export const MIN_WIDTH_AND = 210;
export const MAX_HEIGHT_AND = 320;
export const MIN_HEIGHT_AND = 80;

export const MIN_OFFSET_WIDTH = 280;
export const MIN_OFFSET_HEIGHT = 280;

export const MIN_THUMB_WIDTH = 322;

export const PREVIEW_MEDIA_TYPES = ["image", "video", "audio", "file", GIF_FORMAT, STICKER_FORMAT];
export const MEDIA_MESSAGE_TYPES = ["image", "video", "audio", "file", GIF_FORMAT, STICKER_FORMAT];
export const INITIAL_LOAD_MEDIA_LIMIT = 20;
export const LOAD_MORE_MEDIA_LIMIT = 10;
export const CAPTION_CHARACTER_LIMIT = 1024;
export const ALLOWED_KEY_CODES = [8, 35, 36, 37, 38, 39, 40, 46, 65, 67, 86];
export const TYPE_DELAY_TIME = 5000;
export const CHAT_HISTORY_LIMIT = 20;

export const CONNECTED = "CONNECTED";
export const INLINE_FLEX = "inline-flex";
export const INVALID_STORENAME = "store name is not mentioned";
export const NO_INTERNET = "Please check your Internet connection";
export const SESSION_LOGOUT = "The session has been logged out";
export const RECONNECT_GET_CHAT_LIMIT = 50;
export const NO_INTERNET_TOAST = "Make sure your computer has active internet connection";
export const PROFILE_UPDATE_SUCCESS = "Profile updated successfully";
export const INITIALS_COLOR_CODES = [
  "#9068BE",
  "#E62739",
  "#845007",
  "#3A4660",
  "#1D1E22",
  "#BE7D6A",
  "#005995",
  "#600473",
  "#CD5554",
  "#00303F",
  "#BE4F0C",
  "#4ABDAC",
  "#FC4A1A",
  "#368CBF",
  "#7EBC59",
  "#201D3A",
  "#269CCC",
  "#737272",
  "#237107",
  "#52028E",
  "#AF0D74",
  "#6CB883",
  "#0DAFA4",
  "#A71515",
  "#157FA7",
  "#7E52B1",
  "#27956A",
  "#9A4B70",
  "#FBBE30",
  "#ED3533",
  "#571C8D",
  "#54181C",
  "#9B6700",
  "#6E8E14",
  "#0752A1",
  "#BF6421",
  "#00A59C",
  "#9F0190",
  "#AE3A3A",
  "#858102",
  "#027E02",
  "#F66E54"
];

export const AGENTLOGIN = "agent";
export const CONSUMER = "consumer";
export const PSYCHIC_SM = "psychic";

export const IMPERSONATING_LOGIN_USER_DATA_SM = "impersonating_login_user_data";

export const specialitiesOptionListWeb = [
  { label: "Career and Money", key: "Career and Money" },
  { label: "Family and Friends", key: "Family and Friends" },
  { label: "Love, Sex and Romance", key: "Love Sex and Romance" },
  { label: "Self-Love and Care", key: "Self-Love and Care" },
  { label: "Spirituality", key: "Spirituality" },
  { label: "Clairaudient", key: "Clairaudient" },
  { label: "Clairsentient", key: "Clairsentient" },
  { label: "Clairvoyant", key: "Clairvoyant" },
  { label: "Empath", key: "Empath" },
  { label: "Energy Healing", key: "Energy Healing" },
  { label: "Intuitive", key: "Intuitive" },
  { label: "Lost Object", key: "Lost Object" },
  { label: "Mediumship", key: "Mediumship" },
  { label: "Pet Psychic", key: "Pet Psychic" }
];

export const specialitiesOptionList = [
  { label: "Clairaudient", key: "Clairaudient" },
  { label: "Clairsentient", key: "Clairsentient" },
  { label: "Clairvoyant", key: "Clairvoyant" },
  { label: "Energy Healing", key: "Energy Healing" },
  { label: "Intuitive/Empath", key: "Intuitive/Empath" },
  { label: "Love Psychic", key: "Love Psychic" },
  { label: "Pet Psychic", key: "Pet Psychic" },
  { label: "Psychic Medium", key: "Psychic Medium" }
];

export const toolOptionList = [
  { label: "Angel Cards", key: "Angel Cards" },
  { label: "Astrology", key: "Astrology" },
  { label: "Can Read Without Tools", key: "Can Read Without Tools" },
  { label: "Cartomancy", key: "Cartomancy" },
  { label: "Crystals", key: "Crystals" },
  { label: "Dowsing", key: "Dowsing" },
  { label: "Numerology", key: "Numerology" },
  { label: "Reiki/Healing", key: "Reiki/Healing" },
  { label: "Tarot", key: "Tarot" }
];

export const communityStyleOptionList = [
  { label: "Compassionate", key: "Compassionate" },
  { label: "Conversational", key: "Conversational" },
  { label: "Direct", key: "Direct" },
  { label: "Easy", key: "Easy" },
  { label: "Expressive", key: "Expressive" },
  { label: "Inspirational", key: "Inspirational" },
  { label: "Thoughtful", key: "Thoughtful" }
];

export const sortedBy = [
  {
    label: "Highest Rated",
    key: "1"
  }
];

export const BONUS_DOLLARS_EMPTY = "Bonus dollars cannot be Empty, 0 or less than 0";
export const REASON_EMPTY = "Reason cannot be Empty";
