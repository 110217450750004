import { toast } from "react-toastify";
import Config from "../../../config";
import {
  ALLOWED_ALL_FILE_FORMATS,
  ALLOWED_AUDIO_FORMATS,
  ALLOWED_DOCUMENT_FORMATS,
  ALLOWED_IMAGE_VIDEO_FORMATS,
  ALLOWED_IMAGE_FORMATS,
  ALLOWED_VIDEO_FORMATS,
  IMAGE_FORMATS,
  VIDEO_FORMATS,
  IMAGE_VIDEO_FORMATS,
  AUDIO_FORMATS,
  DOCUMENT_FORMATS,
  FEATURE_RESTRICTION_ERROR_MESSAGE
} from "../../../Helpers/Constants";
import { getMessageType } from "../../../Helpers/Utility";
import SDK from "../../SDK";
import { getFromLocalStorageAndDecrypt } from "../WebChatEncryptDecrypt";
import {
  SELECTED_FILE_AUDIO,
  SELECTED_FILE_FILE,
  SELECTED_FILE_IMAGE,
  SELECTED_FILE_IMAGE_VIDEO,
  SELECTED_FILE_VIDEO
} from "../../../Helpers/Chat/Constant";
import { pluralize } from "../../../Helpers/Chat/ChatHelper";
import { REACT_APP_VIDEO_SIZE_LIMIT } from "../../processENV";

const { maxAllowedMediaCount } = Config;
let connectionStatus = "";
let sizeFailedFileCount = 0;

const getMaxAllowedFileSize = (mediaType) => {
  let settings = getFromLocalStorageAndDecrypt("settings");
  const { videoSizeLimit, fileSizeLimit } = settings || {};
  // console.log(settings,'getFromLocalStorageAndDecrypt');
  if (mediaType === "image") return Number(REACT_APP_VIDEO_SIZE_LIMIT);
  else if (mediaType === "video") return Number(REACT_APP_VIDEO_SIZE_LIMIT);
  else if (mediaType === "audio") return Number(REACT_APP_VIDEO_SIZE_LIMIT);
  else if (mediaType === "file") return Number(REACT_APP_VIDEO_SIZE_LIMIT);
  return Number(REACT_APP_VIDEO_SIZE_LIMIT);
};
const validateFileSize = (file) => {
  const filemb = Math.round(file.size / 1000);
  const fileType = SDK.getFileTypeByFileInstance(file);
  const mediaType = getMessageType(fileType, file);
  const maxAllowedSize = getMaxAllowedFileSize(mediaType);

  if (filemb > maxAllowedSize * 1024) {
    sizeFailedFileCount = sizeFailedFileCount + 1;
    const message = `${pluralize(sizeFailedFileCount, "file")} have exceeded the size limit of ${maxAllowedSize} MB`;
    return Promise.resolve({ message });
  }
  return Promise.resolve(true);
};

/**
 * @param  {string} name=""
 * find last "DOT" and get file Type
 */
export function getExtension(name = "") {
  if (!name) return "";
  const lastDotIndex = name.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return "";
  }
  const lastDot = name.substring(lastDotIndex + 1, name.length);
  return "." + lastDot;
}

const validateFileExtension = (file, mediaType) => {
  const featureFlags = getFromLocalStorageAndDecrypt("featureRestrictionFlags");
  const {
    isImageAttachmentEnabled = false,
    isVideoAttachmentEnabled = false,
    isAudioAttachmentEnabled = false,
    isDocumentAttachmentEnabled = false
  } = featureFlags;
  const fileExtension = getExtension(file.name);
  const allowedFilescheck = new RegExp("([a-zA-Z0-9s_\\.-:])+(" + ALLOWED_ALL_FILE_FORMATS.join("|") + ")$", "i");
  const allowedImageVideoFilescheck = new RegExp(
    "([a-zA-Z0-9s_\\.-:])+(" + ALLOWED_IMAGE_VIDEO_FORMATS.join("|") + ")$",
    "i"
  );
  const allowedImageFilescheck = new RegExp("([a-zA-Z0-9s_\\.-:])+(" + ALLOWED_IMAGE_FORMATS.join("|") + ")$", "i");
  const allowedVideoFilescheck = new RegExp("([a-zA-Z0-9s_\\.-:])+(" + ALLOWED_VIDEO_FORMATS.join("|") + ")$", "i");
  const allowedAudioFilescheck = new RegExp("([a-zA-Z0-9s_\\.-:])+(" + ALLOWED_AUDIO_FORMATS.join("|") + ")$", "i");
  const allowedDocFilescheck = new RegExp("([a-zA-Z0-9s_\\.-:])+(" + ALLOWED_DOCUMENT_FORMATS.join("|") + ")$", "i");
  const fileType = SDK.getFileTypeByFileInstance(file);
  let message = "Unsupported file format. Files allowed: ";
  if (toast.error.length > 1) {
    toast.dismiss();
  }
  if (!fileExtension) {
    toast.error(message);
    return Promise.resolve(false);
  } else if (!allowedImageVideoFilescheck.test(fileExtension) && mediaType === SELECTED_FILE_IMAGE_VIDEO) {
    message = message + `${ALLOWED_IMAGE_VIDEO_FORMATS.join(", ")}`;
    toast.error(message);
    return Promise.resolve(false);
  } else if (!allowedImageFilescheck.test(fileExtension) && mediaType === SELECTED_FILE_IMAGE) {
    message = message + `${ALLOWED_IMAGE_FORMATS.join(", ")}`;
    toast.error(message);
    return Promise.resolve(false);
  } else if (!allowedVideoFilescheck.test(fileExtension) && mediaType === SELECTED_FILE_VIDEO) {
    message = message + `${ALLOWED_VIDEO_FORMATS.join(", ")}`;
    toast.error(message);
    return Promise.resolve(false);
  } else if (!allowedAudioFilescheck.test(fileExtension) && mediaType === SELECTED_FILE_AUDIO) {
    message = message + `${ALLOWED_AUDIO_FORMATS.join(", ")}`;
    toast.error(message);
    return Promise.resolve(false);
  } else if (!allowedDocFilescheck.test(fileExtension) && mediaType === SELECTED_FILE_FILE) {
    message = message + `${ALLOWED_DOCUMENT_FORMATS.join(", ")}`;
    toast.error(message);
    return Promise.resolve(false);
  } else if (mediaType === undefined || "") {
    //this case is works when drag & drop from Conversation Page
    if (
      (isImageAttachmentEnabled &&
        isVideoAttachmentEnabled &&
        isAudioAttachmentEnabled &&
        isDocumentAttachmentEnabled) ||
      (!fileType &&
        (isImageAttachmentEnabled ||
          isVideoAttachmentEnabled ||
          isAudioAttachmentEnabled ||
          isDocumentAttachmentEnabled))
    ) {
      if (!allowedFilescheck.test(fileExtension)) {
        message = message + `${ALLOWED_ALL_FILE_FORMATS.join(", ")}`;
        toast.error(message);
        return Promise.resolve(false);
      }
    } else if (IMAGE_VIDEO_FORMATS.includes(fileType) && isImageAttachmentEnabled && isVideoAttachmentEnabled) {
      if (!allowedImageVideoFilescheck.test(fileExtension)) {
        message = message + `${ALLOWED_IMAGE_VIDEO_FORMATS.join(", ")}`;
        toast.error(message);
        return Promise.resolve(false);
      }
    } else if (IMAGE_FORMATS.includes(fileType) && isImageAttachmentEnabled) {
      if (!allowedImageFilescheck.test(fileExtension)) {
        message = message + `${ALLOWED_IMAGE_FORMATS.join(", ")}`;
        toast.error(message);
        return Promise.resolve(false);
      }
    } else if (VIDEO_FORMATS.includes(fileType) && isVideoAttachmentEnabled) {
      if (!allowedVideoFilescheck.test(fileExtension)) {
        message = message + `${ALLOWED_VIDEO_FORMATS.join(", ")}`;
        toast.error(message);
        return Promise.resolve(false);
      }
    } else if (AUDIO_FORMATS.includes(fileType) && isAudioAttachmentEnabled) {
      if (!allowedAudioFilescheck.test(fileExtension)) {
        message = message + `${ALLOWED_AUDIO_FORMATS.join(", ")}`;
        toast.error(message);
        return Promise.resolve(false);
      }
    } else if (DOCUMENT_FORMATS.includes(fileType) && isDocumentAttachmentEnabled) {
      if (!allowedDocFilescheck.test(fileExtension)) {
        message = message + `${ALLOWED_DOCUMENT_FORMATS.join(", ")}`;
        toast.error(message);
        return Promise.resolve(false);
      }
    } else {
      message = FEATURE_RESTRICTION_ERROR_MESSAGE;
      toast.error(message);
      return Promise.resolve(false);
    }
  }
  return validateFileSize(file);
};

export const dispatchErrorMessage = () => {
  if (connectionStatus === "CONNECTED") return true;
  const message = `Please check your internet connection`;
  return toast.error(message);
};

export const setConnectionStatus = (status) => (connectionStatus = status);

export const sendErrorMessage = () => {
  const message = `Can't share more than ${maxAllowedMediaCount} media items.`;
  toast.error(message);
};

export const validateFile = (fileArray, mediaType) => {
  sizeFailedFileCount = 0;
  return Promise.all(fileArray.map((file) => validateFileExtension(file, mediaType))).then((res) => {
    let message = null;
    const resToReturn = res.map((data) => {
      if (data?.message) {
        message = data.message;
        return false;
      }
      return data;
    });

    if (message) {
      toast.error(message);
    }
    return Promise.resolve(resToReturn);
  });
};
