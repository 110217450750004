import {
  TURN_ON_OFF_CHARGE,
  TURN_ON_OFF_CHARGE_LIST_STATUS,
  TURN_ON_OFF_CHARGE_REMOVE,
  TURN_ON_OFF_CHARGE_UPDATE
} from "./Constants";

export const getTrunOnOffChargeAction = (payload) => {
  return {
    type: TURN_ON_OFF_CHARGE,
    payload
  };
};

export const trunOnOffChargeRemoveAction = () => {
  return {
    type: TURN_ON_OFF_CHARGE_REMOVE
  };
};

export const trunOnOffChargeUpdateAction = (payload) => {
  return {
    type: TURN_ON_OFF_CHARGE_UPDATE,
    payload
  };
};

export const trunOnOffChargeListStatusAction = (payload) => {
  return {
    type: TURN_ON_OFF_CHARGE_LIST_STATUS,
    payload
  };
};
