const config = {
  attachement: true,
  videoDuaration: 40,
  audioDuaration: 60,
  fileSize: 50,
  imageFileSize: 10,
  videoFileSize: 30,
  audioFileSize: 30,
  documentFileSize: 20,
  maxAllowedMediaCount: 1,
  maximumAllowedUsersToForward: 5,
  maximumCharForwardPopUp: 60,
  internetErrorMessage: "Please check your Internet connection",
  helpUrl: "https://uat1-webchat.yabsys.com/help",
  demoUrl: "localhost",
  boxLayout: true,
  reportMembers: 5
};
export default config;
