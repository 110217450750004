import mixpanel from "mixpanel-browser";
import { REACT_APP_MIXPANEL_TOKEN } from "../processENV";

console.log("REACT_APP_MIXPANEL_TOKEN", REACT_APP_MIXPANEL_TOKEN);

// Initialize Mixpanel with your project token
mixpanel.init(REACT_APP_MIXPANEL_TOKEN, {
  debug: true, // Enable for debugging during development
  ignore_dnt: true // Ignore "Do Not Track" setting
});

export const mixpanelCatchAsync = (fn) => {
  return (...args) => {
    try {
      console.log(args, "Mix Panel Data responce");
      fn(...args);
    } catch (error) {
      console.log("Mix Panel Error", error);
    }
  };
};

export default mixpanel;
