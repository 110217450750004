import moment from "moment";
import {
  FETCH_BROADCAST_ADDITIONAL_DATA,
  FETCH_BROADCAST_CLEAR,
  FETCH_BROADCAST_FAILURE,
  FETCH_BROADCAST_REFRESH_UPDATE,
  FETCH_BROADCAST_REQUEST,
  FETCH_BROADCAST_SEARCH_FILTER,
  FETCH_BROADCAST_SUCCESS,
  FETCH_BROADCAST_UPDATE,
  NAME_UPDATE,
  LAST_MESSAGE_UPDATE,
  DELETE_PARTICULAR_LIST,
  FETCH_UPDATE_REQUIRED,
  BROADCAST_REQUEST_STATUS,
  DECREMENT_NOTIFICATION_COUNT,
  RESET_NOTIFICATION_COUNT
} from "../Actions/BroadCastAction";

const initialState = {
  msgReceivedStatus: null,
  notificationCount: parseInt(localStorage.getItem("notificationCount")) || 0,
  loaderStatus: false,
  updateRequired: moment().unix().toString(),
  filterItem: [],
  originArray: [],
  page: 0,
  limit: 20,
  search: "",
  error: "",
  additionalField: {
    name: "",
    message: "",
    createPopUpOpen: false,
    editMode: false,
    editDetails: null,
    viewModel: "",
    deleteCompletedLeaveToHome: false,
    render: ""
  }
};

export const BroadCastListReducer = (state = initialState, action) => {
  switch (action.type) {
    case BROADCAST_REQUEST_STATUS:
      const newCount = state.notificationCount + 1;
      localStorage.setItem("notificationCount", newCount); // Save to localStorage
      return {
        ...state,
        msgReceivedStatus: moment().unix().toString(),
        notificationCount: newCount
      };

    case DECREMENT_NOTIFICATION_COUNT:
      const decreasedCount = Math.max(0, state.notificationCount - 1);
      localStorage.setItem("notificationCount", decreasedCount); // Save to localStorage
      return {
        ...state,
        notificationCount: decreasedCount
      };

    case RESET_NOTIFICATION_COUNT:
      localStorage.setItem("notificationCount", 0); // Reset count in localStorage
      return {
        ...state,
        notificationCount: 0
      };

    case FETCH_BROADCAST_REQUEST:
      return {
        ...state,
        loaderStatus: true
      };
    case FETCH_BROADCAST_SUCCESS:
      const data = action.payload;

      const sortedData = [...data.data].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      const successUpdateRes = {
        ...state,
        filterItem: sortedData,
        page: state.page + 1,
        error: "",
        loaderStatus: false,
        originArray: sortedData
      };
      state = successUpdateRes;

      return successUpdateRes;

    case FETCH_BROADCAST_CLEAR:
      state = initialState;
      return initialState;

    case FETCH_BROADCAST_FAILURE:
      return {
        ...state,
        loading: false,
        filterItem: [],
        error: action.payload
      };

    case FETCH_BROADCAST_REFRESH_UPDATE:
      const { item } = action.payload;
      let updateFilter = [...state.filterItem, item];
      state.filterItem = updateFilter;
      return {
        ...state,
        filterItem: updateFilter
      };

    case FETCH_BROADCAST_SEARCH_FILTER:
      const search = action.payload;
      if (search != "") {
        state.filterItem = state.originArray.filter(
          (i) => !!String(i.broadcastname).toLowerCase().match(String(search).toLowerCase())
        );
        state.loaderStatus = false;
      } else {
        state.filterItem = state.originArray;
        state.loaderStatus = false;
      }
      return {
        ...state
      };

    case FETCH_BROADCAST_ADDITIONAL_DATA:
      state.additionalField = { ...state.additionalField, ...action.payload };
      return state;

    case DELETE_PARTICULAR_LIST:
      let { broadCastId, renderDelete = "" } = action.payload;
      state.originArray = state.originArray.filter((i) => i.Broadcast_id != broadCastId);
      state.filterItem = state.originArray;

      if (renderDelete) {
        state.additionalField = { ...state.additionalField, render: renderDelete };
      }
      return state;

    case LAST_MESSAGE_UPDATE:
      const { message, id, render = "" } = action.payload;
      const updateMessageIndex = state.originArray.findIndex((i) => i.Broadcast_id == id);
      if (updateMessageIndex != -1) {
        state.originArray[updateMessageIndex] = {
          ...state.originArray[updateMessageIndex],
          msgBody: { message: message, messageType: "text" },
          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
        };
        const sortedData = [...state.originArray].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        state.filterItem = sortedData;
        if (render) {
          state.additionalField = { ...state.additionalField, render: render };
        }
      }
      return state;

    case NAME_UPDATE:
      var { name, id: idTemp, renderName = "" } = action.payload;
      var updateMessageIndexT = state.originArray.findIndex((i) => i.Broadcast_id == idTemp);
      if (updateMessageIndexT != -1) {
        state.originArray[updateMessageIndexT] = {
          ...state.originArray[updateMessageIndexT],
          broadcastname: name
        };
        state.filterItem = state.originArray;
      }

      if (renderName) {
        state.additionalField = { ...state.additionalField, render: renderName };
      }
      return state;
    case FETCH_BROADCAST_UPDATE:
      state = { ...state, ...action.payload };
      return state;

    case FETCH_UPDATE_REQUIRED: {
      const { renderUpdate = "" } = action.payload;
      if (state.updateRequired === renderUpdate) {
        return state;
      }
      return {
        ...state,
        updateRequired: renderUpdate
      };
    }
    default:
      return state;
  }
};
