import {
  UNREAD_MESSAGE_UPDATE,
  UNREAD_MESSAGE_DELETE,
  RESET_UNREAD_COUNT,
  UPDATE_MUTE_RECENT_CHAT,
  CLEAR_ALL_CHAT
} from "../Actions/Constants";

const initialState = {
  id: null,
  activeUser: {},
  unreadDataObj: {}
};
let msgCount = 0;

const updateCount = (unreadDataObj, currentData) => {
  const existUser =
    unreadDataObj[currentData.fromUserId] && unreadDataObj[currentData.fromUserId][currentData.chatType];
  const currentDataCount =
    currentData.count ||
    (unreadDataObj[currentData.fromUserId] &&
      unreadDataObj[currentData.fromUserId][currentData.chatType] &&
      unreadDataObj[currentData.fromUserId][currentData.chatType].count);
  if (!existUser) {
    unreadDataObj[currentData.fromUserId] = {
      [currentData.chatType]: {
        count: currentDataCount || 1
      }
    };
  } else if (currentData.isRefresh) {
    unreadDataObj[currentData.fromUserId] = {
      [currentData.chatType]: {
        ...existUser,
        count: existUser.count
      }
    };
  } else {
    const count = existUser.count;
    unreadDataObj[currentData.fromUserId] = {
      [currentData.chatType]: { ...existUser, count: count + 1 }
    };
  }
  return unreadDataObj;
};

const deleteCount = (unreadDataObj, currentData) => {
  const existUser =
    unreadDataObj[currentData.fromUserId] && unreadDataObj[currentData.fromUserId][currentData.chatType];
  if (unreadDataObj[currentData.fromUserId]) {
    delete unreadDataObj[currentData.fromUserId][currentData.chatType];
    msgCount = msgCount - existUser.count;
  }
  return unreadDataObj;
};

export function UnreadCountReducer(state = { ...initialState }, action = {}) {
  const { payload } = action;
  switch (action.type) {
    case UNREAD_MESSAGE_UPDATE:
      return {
        ...state,
        id: payload.id,
        unreadDataObj: updateCount(state.unreadDataObj, payload.data)
      };
    case UNREAD_MESSAGE_DELETE:
      return {
        ...state,
        id: payload.id,
        activeUser: payload.data,
        unreadDataObj: deleteCount(state.unreadDataObj, payload.data)
      };
    case RESET_UNREAD_COUNT:
      return {
        id: null,
        activeUser: {},
        unreadDataObj: {}
      };
    case UPDATE_MUTE_RECENT_CHAT:
      return {
        ...state
      };
    case CLEAR_ALL_CHAT:
      return {
        ...state,
        id: payload.fromUserId,
        unreadDataObj: {}
      };

    default:
      return state;
  }
}
