import moment from "moment";
import { getActivePlan } from "../../Helpers/Chat/ChatHelper";
import mixpanel, { mixpanelCatchAsync } from "./MixpanelConfig";
import Store from "../../Store";
import { getFromLocalStorageAndDecrypt } from "../WebChat/WebChatEncryptDecrypt";
import { showConfrence } from "../../Actions/CallAction";

export const createConsumerProfile = mixpanelCatchAsync((userDetails) => {
  mixpanel.identify(userDetails.userId);

  mixpanel.people.set({
    $name: userDetails.nickName,
    consumer_username: userDetails.username,
    consumer_bio: userDetails.status,
    phone_number: userDetails.mobileNumber,
    subscription_status: "Free Member",
    member_role: userDetails.is_psychic === "1" ? ["Advisor", "Consumer"] : ["Consumer"],
    $email: userDetails.email,
    profile_picture_added_flag: userDetails.image ? "Yes" : "No",
    member_joining_date: new Date().toISOString(),
    $user_id: userDetails.userId
  });

  mixpanel.track("Consumer Profile Created", {
    $user_id: userDetails.userId
  });
});

export const setSuperProperties = mixpanelCatchAsync((id) => {
  mixpanel.register({
    $user_id: id
  });
});

export const mixpanelReset = mixpanelCatchAsync(() => {
  mixpanel.reset();
});

export const updateConsumerProfile = mixpanelCatchAsync(async (userDetails) => {
  const subscription = await getActivePlan();
  mixpanel.identify(userDetails.userId);
  mixpanel.people.set({
    $name: userDetails.nickName,
    consumer_username: userDetails.username,
    consumer_bio: userDetails.status,
    phone_number: userDetails.mobileNumber,
    subscription_status: subscription.data.activePlan === null ? "Free Member" : "Subscriber",
    member_role: userDetails.is_psychic === "1" ? ["Advisor", "Consumer"] : ["Consumer"],
    $email: userDetails.email,
    profile_picture_added_flag: userDetails.image ? "Yes" : "No",
    $user_id: userDetails.userId
  });
});

export const addedFunds = mixpanelCatchAsync((details) => {
  const {
    fund_added_value,
    fund_added_origin,
    fund_added_date,
    bonus_added_flag,
    bonus_added_type,
    bonus_added_value,
    payment_method
  } = details;
  mixpanel.track("Consumer Added Funds", {
    fund_added_value,
    fund_added_origin,
    fund_added_date,
    bonus_added_flag,
    bonus_added_type,
    bonus_added_value,
    payment_method
  });
});

export const bookedAppointments = mixpanelCatchAsync((details) => {
  const {
    reading_channel,
    appointment_origin,
    appointment_id,
    appointment_is_recurring_flag,
    reading_type,
    advisor_id,
    appointment_value,
    appointment_duration,
    appointment_scheduled_date,
    special_request_flag = "No"
  } = details;

  mixpanel.track("Consumer Booked Appointment", {
    reading_channel,
    appointment_origin,
    appointment_id,
    appointment_is_recurring_flag,
    reading_type,
    advisor_id,
    appointment_value,
    appointment_duration,
    appointment_scheduled_date: moment(appointment_scheduled_date).utc().format(),
    appointment_made_date: new Date().toISOString(),
    special_request_flag: special_request_flag
    // bonus_amount_withheld,
    // bonus_withheld_flag
  });
});

export const chatReadingCompeted = mixpanelCatchAsync((details) => {
  mixpanel.track("Chat Reading Completed", details);
});

export const audioVideoCallEnd = mixpanelCatchAsync((details) => {
  mixpanel.track("Audio Video Reading Completed", details);
});

export const advisorProfileCreate = mixpanelCatchAsync((obj) => {
  mixpanel.track("Advisor Profile Created", obj); // still in progress for future enhancement
});

export const advisorProfileUpdate = mixpanelCatchAsync((obj, userId) => {
  mixpanel.identify(userId);
  mixpanel.people.set(obj);
});

export const advisorSetAvailability = mixpanelCatchAsync((obj) => {
  mixpanel.track("Advisor Sets Availability", obj);
});

export const advisorAvailabilityUpdate = mixpanelCatchAsync((obj) => {
  mixpanel.track("Advisor Updates Availability", obj);
});

export const broadCastMessageSend = mixpanelCatchAsync((obj) => {
  mixpanel.track("Advisor Broadcasted", obj);
});

export const getOTP = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on Get OTP", obj);
});

export const verifyOTP = mixpanelCatchAsync(() => {
  mixpanel.track("Clicked on Verify OTP");
});

export const advisorProfile = mixpanelCatchAsync((userName) => {
  let data = {
    advisor_id: userName
  };
  mixpanel.track("Clicked on Advisor Profile", data);
});

export const onClickReel = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on Content", obj);
});

export const onClickAddFund = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on Add Funds", obj);
});

export const onEnterFund = mixpanelCatchAsync((obj) => {
  mixpanel.track("Consumer Entered Fund Amount", obj);
});

export const onEnterPaymentDetails = mixpanelCatchAsync(() => {
  mixpanel.track("Consumer Entered Payment Details");
});

export const onClickChatReading = mixpanelCatchAsync((userName) => {
  mixpanel.track("Clicked on Chat Reading", {
    advisor_id: userName
  });
});

export const onClickBookAppoinment = mixpanelCatchAsync((origin) => {
  mixpanel.track("Clicked on Book Appointment", {
    appointment_origin: origin
  });
});

export const onEnterBookAppoinmentDetails = mixpanelCatchAsync((obj) => {
  mixpanel.track("Consumer Entered Appointment Details", obj);
});

export const onClickAudioVideoCall = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on Audio Video Call", obj);
});

export const onClickNewRecording = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on New Recording", obj);
});

export const updateLiveAvailability = mixpanelCatchAsync((obj) => {
  mixpanel.track("Advisor Updated Live Availability", obj);
});

export const onClickContentDraft = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on a Content Draft", obj);
});

export const onClickContentDelete = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on Delete Content", obj);
});

export const onClickCreateBroadcast = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on Create Broadcast", obj);
});

export const onClickCreateAvailability = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on Create New Availability", obj);
});

export const onClickExistAvailability = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on Existing Availability", obj);
});

export const onClickReportFlag = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on Report Flag", obj);
});

export const onClickNotification = mixpanelCatchAsync(() => {
  mixpanel.track("Clicked on Notification");
});

export const advisorPublishedReel = mixpanelCatchAsync((obj) => {
  mixpanel.track("Advisor Published Content", obj);
});

export const AdvisorDeleteReel = mixpanelCatchAsync((obj) => {
  mixpanel.track("Advisor Deleted Content", obj);
});

export const issueReported = mixpanelCatchAsync((obj) => {
  mixpanel.track("Issue Reported", obj);
});
export const onClickAddDollarPrompt = mixpanelCatchAsync((obj) => {
  mixpanel.track("Clicked on Add Dollar Prompt", obj);
});
